export const acdata = {
  nodes: [
    { nodeX1: 0, nodeY1: 4, nodeHeight: 31, nodeX0: 1, nodeY0: 4, name: 'Red', color: '#EF8884' },
    { nodeX1: 12, nodeY1: 61, nodeHeight: 31, nodeX0: 1, nodeY0: 61, name: 'Yellow', color: '#EFE773' },
    { nodeX1: 24, nodeY1: 118, nodeHeight: 31, nodeX0: 1, nodeY0: 118, name: 'Green', color: '#73E7AD' },
    { nodeX1: 36, nodeY1: 167, nodeHeight: 31, nodeX0: 1, nodeY0: 177, name: 'Light Purple', color: '#7BBDE7' },
    { nodeX1: 48, nodeY1: 224, nodeHeight: 31, nodeX0: 1, nodeY0: 234, name: 'Pink', color: '#E784EC' },
    { nodeX1: 60, nodeY1: 266, nodeHeight: 31, nodeX0: 1, nodeY0: 294, name: 'Purple', color: '#9C84E7' },
    { nodeX1: 12, nodeY1: 39, nodeHeight: 35, nodeX0: 489, nodeY0: 60, name: 'Blue', color: '#2139EF' },
    { nodeX1: 24, nodeY1: 113, nodeHeight: 35, nodeX0: 489, nodeY0: 145, name: 'SkyBlue', color: '#31FBF7' },
    { nodeX1: 36, nodeY1: 190, nodeHeight: 35, nodeX0: 489, nodeY0: 230, name: 'Black', color: '#000000' },
  ],
  links: [
    // Red - Blue - B, SkyBlue - S, and Black - B
    { turn4: false, radiusX0: 0, linkX1: 7, radiusY0: 7, linkY0: 0, source: 0, target: 6, value: 1, color: '#EF8884', yP: 15, xP: 90, cPosition: 5, cXaxis: [80, 200, 400, 441], cYaxis: { 3: 20 } },

    { turn4: false, radiusX0: 0, linkX1: 7, radiusY0: 7, linkY0: 0, source: 0, target: 6, value: 1, color: '#EF8884', yP: 18, xP: 88, cPosition: 3, cXaxis: [120, 280], cYaxis: {} },

    { turn4: false, radiusX0: 0, linkX1: 10.5, radiusY0: 0, linkY0: 0, source: 0, target: 7, value: 1, color: '#EF8884', yP: 38, xP: 86, cPosition: 4, cXaxis: [60, 250, 350], cYaxis: {} }, // S

    { turn4: false, radiusX0: 0, linkX1: 13.5, radiusY0: 0, linkY0: 0, source: 0, target: 8, value: 1, color: '#EF8884', yP: 63.5, xP: 84, cPosition: 5, cXaxis: [40, 100, 250, 402], cYaxis: { 3: 40 } }, // B

    { turn4: false, radiusX0: 0, linkX1: 13.5, radiusY0: 0, linkY0: 0, source: 0, target: 8, value: 1, color: '#EF8884', yP: 69, xP: 82, cPosition: 2, cXaxis: [350], cYaxis: {} }, // B

    // Yellow
    { turn4: false, radiusX0: 0, linkX1: 7, radiusY0: 14, linkY0: 0, source: 1, target: 6, value: 1, color: '#EFE773', yP: 20, xP: 78, cPosition: 3, cXaxis: [90, 270], cYaxis: {} }, // B

    { turn4: false, radiusX0: 0, linkX1: 7, radiusY0: 14, linkY0: 0, source: 1, target: 6, value: 1, color: '#EFE773', yP: 22, xP: 76, cPosition: 4, cXaxis: [50, 240, 300], cYaxis: {} }, // B

    { turn4: false, radiusX0: 0, linkX1: 10.5, radiusY0: 0, linkY0: 0, source: 1, target: 7, value: 1, color: '#EFE773', yP: 40, xP: 74, cPosition: 3, cXaxis: [80, 150], cYaxis: {} }, // S

    { turn4: false, radiusX0: 0, linkX1: 10.5, radiusY0: 0, linkY0: 0, source: 1, target: 7, value: 1, color: '#EFE773', yP: 45, xP: 72, cPosition: 3, cXaxis: [40, 260], cYaxis: {} }, // S

    { turn4: false, radiusX0: 0, linkX1: 13.5, radiusY0: 0, linkY0: 0, source: 1, target: 8, value: 1, color: '#EFE773', yP: 66, xP: 70, cPosition: 3, cXaxis: [60, 290], cYaxis: {} }, // B

    // Green
    { turn4: true, radiusX0: 0, linkX1: 7, radiusY0: 0, linkY0: 0, source: 2, target: 6, value: 1, color: '#73E7AD', yP: 24.5, xP: 63, cPosition: 5, cXaxis: [70, 225, 240, 380], cYaxis: { 1: -10 } }, // B

    { turn4: false, radiusX0: 0, linkX1: 10.5, radiusY0: 0, linkY0: 0, source: 2, target: 7, value: 1, color: '#73E7AD', yP: 40, xP: 62, cPosition: 4, cXaxis: [45, 300, 340], cYaxis: { 2: 25 } }, // S

    { turn4: false, radiusX0: 0, linkX1: 10.5, radiusY0: 0, linkY0: 0, source: 2, target: 7, value: 1, color: '#73E7AD', yP: 42, xP: 60, cPosition: 2, cXaxis: [100], cYaxis: {} }, // S

    { turn4: false, radiusX0: 0, linkX1: 13.5, radiusY0: 0, linkY0: 0, source: 2, target: 8, value: 1, color: '#73E7AD', yP: 66, xP: 58, cPosition: 3, cXaxis: [260, 287], cYaxis: { 1: 50 } }, // B

    { turn4: false, radiusX0: 0, linkX1: 13.5, radiusY0: 0, linkY0: 0, source: 2, target: 8, value: 1, color: '#73E7AD', yP: 70, xP: 56, cPosition: 3, cXaxis: [240, 330], cYaxis: { 1: 102 } }, // B

    // Light Purple
    { turn4: false, radiusX0: 10, linkX1: 10.5, radiusY0: 0, linkY0: 10, source: 3, target: 7, value: 3, color: '#7BBDE7', yP: 49, xP: 30, cPosition: 3, cXaxis: [300, 360], cYaxis: { 0: -5, 1: -10 } }, // S

    { turn4: false, radiusX0: 10, linkX1: 13.5, radiusY0: 0, linkY0: 10, source: 3, target: 8, value: 1, color: '#7BBDE7', yP: 72, xP: 40, cPosition: 3, cXaxis: [180, 211], cYaxis: { 0: 10, 1: 59 } }, // B

    // Pink
    { turn4: false, radiusX0: 10, linkX1: 7, radiusY0: 0, linkY0: 10, source: 4, target: 6, value: 1, color: '#E784EC', yP: 13, xP: 23, cPosition: 5, cXaxis: [40, 118, 150, 300], cYaxis: { 0: 10, 2: -165, 3: -165 } }, // B

    { turn4: false, radiusX0: 10, linkX1: 7, radiusY0: 0, linkY0: 10, source: 4, target: 6, value: 1, color: '#E784EC', yP: 21, xP: 25, cPosition: 2, cXaxis: [90], cYaxis: { 0: 10 } }, // B

    // Purple
    { turn4: false, radiusX0: 25, linkX1: 7, radiusY0: 0, linkY0: 28, source: 5, target: 6, value: 5, color: '#9C84E7', yP: 23, xP: 10, cPosition: 3, cXaxis: [56, 100], cYaxis: { 0: -50, 1: -110 } }, // B

    { turn4: false, radiusX0: 25, linkX1: 13.5, radiusY0: 0, linkY0: 28, source: 5, target: 8, value: 1, color: '#9C84E7', yP: 74.5, xP: 14, cPosition: 2, cXaxis: [120], cYaxis: { 0: -32 } }, // B
  ]
}