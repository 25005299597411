Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/bx_block_dashboard/candidates";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.totalCandidateslabelTitleText = "Total Candidates";
exports.viewDetailsBtn = "View Details";
exports.candidateslabelTitleText = "Candidates";
exports.baseUrl = ""
exports.labelBodyText = "dashboard Body";
exports.dashboardConMngntCardSectionsAPIEndPoint="bx_block_content_management/card_sections";
exports.dashboardSidebarTrialsAPIEndPoint="bx_block_trial/trials"
exports.favouriteEndpoint = "bx_block_favourites/favourites";
exports.favoriteDashboardCards = 'bx_block_content_management/favourite_dashboard_cards';
exports.postApiMethod = 'POST';
exports.deleteApiMethod = 'DELETE';
exports.getApiMethod = 'GET';
// Customizable Area End