import React from "react";

// Customizable Area Start
import { createTheme, ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import WhiteboardController, {
  Props,
} from "./WhiteboardController.web";
import {
  Button,
  AppBar, Snackbar, Alert
} from '@mui/material';
import {
  rectangle, line, arrow, arrow2, zoom
} from "../src/assets";
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
import { Prompt } from 'react-router-dom';
// Customizable Area End

export default class Whiteboard extends WhiteboardController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    window.addEventListener("beforeunload", this.handleBeforeUnload);
    this.initializeUser()
    document.addEventListener('keydown', this.handleKeyDown);
    // document.addEventListener('wheel', this.handleScroll, {passive: false})

    this.drawDragChart(this.chartRef3.current as SVGSVGElement)

  }
  async componentWillUnmount() {
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
    this.removeDeleteButton()
    this.removeEditInput()
  }
 
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <div style={webStyle.container} >
          <div>
          <AppBar position="static" style={webStyle.whiteboardHeader}>
            <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    margin: '0px 30px',
                    gap: 5,
                    maxWidth: '100%',
                    color: 'black'
                  }}>
              <h1 data-test-id="back-to-analytics-button" onClick={this.handleNavigateWhiteboard}>{'< Back'}</h1>
              
              {this.state.isSuperUser && !this.state.canEdit && !this.state.isSaving && (<Button data-test-id="edit-mode-button" onClick={this.handleEditClicked} disableElevation variant="contained" style={{ fontFamily: 'Inter', color: '#6200EA', backgroundColor: '#F0E5FF', width: '80', height: '35px' }}>
                      Edit
              </Button>)}
              {this.state.isSuperUser && this.state.canEdit && (<Button data-test-id="save-button" onClick={this.handleSave} disableElevation variant="contained" style={{ fontFamily: 'Inter', color: '#6200EA', backgroundColor: '#F0E5FF', width: '80', height: '35px' }}>
                      Save
              </Button>)}
            </div>

            
            
          </AppBar>
        </div>
            <div style={webStyle.mainWrapper}>
              <div style={webStyle.secondWrapper}>
                <div style={webStyle.flexdisplay} >
                  {this.state.canEdit && (
                  <div style={webStyle.toolbar}>
                    <button data-test-id="add-button" id="addNodeButton" disabled={this.state.isSaving} onClick={this.handleAddNodeClick} style={webStyle.tool}><img src={rectangle} width={32} height={32}  alt="rectangle" /></button>
                    <button data-test-id="add-arrow-button" id="addArrowButton" disabled={this.state.isSaving} onClick={this.handleAddArrowClick} style={webStyle.tool}><img src={arrow} alt="arrow" width={32} height={32} /></button>
                  </div>)}
                  <svg ref={this.chartRef3} data-test-id="whiteboard-svg"  onClick={this.handleSvgClick} style={{backgroundColor: "white", cursor: this.state.isAddingNode || this.state.isAddingArrow ? 'crosshair' : 'default'}} width={'100%'} height={"100%"}>
                    <g />
                  </svg>{this.state.isSuperUser && (
                  <div style={webStyle.zoombar}>
                    <button style={webStyle.zoomButton} disabled={this.state.isSaving} onClick={this.zoomOut}>-</button>
                    <span style={webStyle.zoomButton}>{Math.round(this.state.zoomLevel*100)}%</span>
                    <button  style={webStyle.zoomButton} disabled={this.state.isSaving} onClick={this.zoomIn}>+</button>
                    <button style={webStyle.zoomButton} disabled={this.state.isSaving} onClick={this.resetZoom}><img src={zoom} alt="zoom" width={23} height={23}/></button>
                  </div>)}
                  <Prompt
                    when={!this.state.isSaved}
                    message="You have unsaved changes. Are you sure you want to leave?"
                  />
                  <Snackbar
                    open={this.state.openPrompt}
                    autoHideDuration={5000}
                    onClose={this.handleClosePrompt}
                  >
                  <Alert
                    onClose={this.handleClosePrompt}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                  >
                    Changes saved successfully
                  </Alert>
                  </Snackbar>
                  <Snackbar
                    open={this.state.openErrorPrompt}
                    autoHideDuration={5000}
                    onClose={this.handleCloseErrorPrompt}
                  >
                  <Alert
                    onClose={this.handleCloseErrorPrompt}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                  >
                    {this.state.errorMessage}
                  </Alert>
                  </Snackbar>
                </div>
              </div>
            </div >
          </div>
        </ThemeProvider>
      </StyledEngineProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  whiteboardHeader: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  container: {
    background: 'linear-gradient(135deg, #F4EEFC 0%, #FAEEEC 100%',
    height: '100vh',
    fontFamily: 'Inter',
    fontWeight: 400,
    display: 'flex',
    flexDirection: 'column',
  },
  tool: {
    width: '50px',
    height: '50px',
    backgroundColor: '#FFF',
    border: 'none',
    borderRadius: 'none',
    transition: 'background-color 0.3s ease',
  },
  zoomButton: {
    backgroundColor: '#FFF',
    border: 'none',
    borderRadius: 'none',
    color: '#676B7E',
    fontFamily: "'Rubik', sans-serif",
    fontWeight: 500,
    fontSize: '14px',
    margin: '0px 5px'
  },
  toolHover: {
    backgroundColor: '#CBD5E1',
  },
  toolbar: {
    position: 'fixed',
    top: 200,
    right: 20,
    padding: 5,
    width: '60px',
    height: '200px',
    borderRadius: '14px',
    zIndex: 10
    
  },
  zoombar: {
    position: 'fixed',
    backgroundColor: '#FFF',
    bottom: 20,
    left: 250,
    padding: 5,
    height: '50px',
    width: '200px',
    borderRadius: '14px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 8px 32px rgba(0, 0, 0, 0.06), 0px 4px 8px rgba(0, 0, 0, 0.03), 0px 25px 50px rgba(0, 0, 0, 0.09)'
  },
  mainWrapper: {
    fontFamily: "'Rubik', sans-serif",
    justifyContent: 'center',
    blur: "100px",
    width: "auto",
    height: "auto",
    display: "flex",
    background: 'linear-gradient(135deg, #F4EEFC 0%, #FAEEEC 100%)',
  },
  secondWrapper: {
    marginTop: '10px',
    width: "100%",
    height: '100%',
  },
  flexdisplay: {
    padding: "30px",
    overflow: 'hidden',
    height: '80vh'
  },
} as const;
// Customizable Area End
