export const group_Logo = require("./assets/logo.svg");
export const layout_Corners_Dashboard = require("./assets/layout-corners-dashboard.svg");
export const search_Icon = require("./assets/search.svg");
export const natasha_Icon = require("./assets/natasha.svg");
export const retouch_Graph = require("./assets/retouch-graph.svg");
export const logout_icon = require("./assets/logout-icon.svg");
export const explorer_Icon = require("./assets/explorer.svg");
export const dashboard_Icon = require("./assets/dashboard.svg");
export const trial_Icon = require("./assets/trials.svg");
export const analytics_Icon = require("./assets/analytics.png");
export const Helios = require("./assets/Helios.svg");
export const HeliosGray = require("./assets/HeliosGray.svg")
export const star_border = require("./assets/star_border.svg");
export const upArrow = require("./assets/upArrow.png");
export const downArrow = require("./assets/downArrow.png");
//export const low_Icon = require("assets/lowIcon.png");

