Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.btnExampleTitle = "CLICK ME";
exports.addDataUrl ="bx_block_content_management/content_managements";
exports.loginAPI2 ="https://staging.accounts.builder.ai/oauth/authorize/?client_id=TrQFIwNuXMkAYFWs_lTpCEp1FkXbsuF6AOPROQlpdnw&redirect_uri=https://builderanalytics-404910-ruby.b404910.dev.eastus.az.svc.builder.cafe/users/auth/aaa/callback&response_type=code&scope=oauth2_uids+openid&state=BuilderAnayltics";
// Customizable Area End