// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'
import { Route } from "react-router-dom";

import Sidebar from "../../components/src/Sidebar.web";
import Iframe from '../../components/src/Iframe.web'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import Dashboard from "../../blocks/dashboard/src/Dashboard.web";
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Analytics from "../../blocks/analytics/src/Analytics";
import AnalyticsChart from "../../blocks/analytics/src/AnalyticsChart.web";
import AnalyticsChart2 from "../../blocks/analytics/src/AnalyticsChart2.web";
import AnalyticsChart4 from "../../blocks/analytics/src/AnalyticsChart4.web";
import Whiteboard from "../../blocks/analytics/src/Whiteboard.web";
import Contentmanagement1 from "../../blocks/contentmanagement1/src/Contentmanagement1.web";
import Contentmanagement from "../../blocks/contentmanagement/src/ContentManagement.web";
import NatashaChatScreen from "../../blocks/contentmanagement/src/NatashaChatScreen.web";
// import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Adminconsole from "../../blocks/adminconsole/src/Adminconsole";
// import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
// import Signuplogin from "../../blocks/signuplogin/src/Signuplogin";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Rolesandpermissions from "../../blocks/rolesandpermissions/src/Rolesandpermissions";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import LandingPage from "../../blocks/landingpage/src/LandingPage.web";
import WhiteboardCollaboration from "../../blocks/whiteboardcollaboration/src/WhiteboardCollaboration.web";

const routeMap = {
  ImportExportData: {
    component: ImportExportData,
    path: "/ImportExportData"
  },
  Dashboard: {
    component: Dashboard,
    path: "/Dashboard"
  },
  DashboardCard: {
    component: Iframe,
    path: "/DashboardCard",
  },
  DashboardCardId: {
    component: Iframe,
    path: "/DashboardCardId/:id",
    exact: true,
  },
  Contentmanagement: {
    component: Contentmanagement,
    path: "/Natasha"
  },
  NatashaChatScreen: {
    component: NatashaChatScreen,
    path: "/NatashaChatScreen"
  },
  Iframe: {
    component: Iframe,
    path: "/Trials"
  },
  Explorer: {
    component: Iframe,
    path: "/Explorer",
  },
  Contentmanagement1: {
    component: Contentmanagement1,
    path: "/Contentmanagement1"
  },
  SocialMediaAccountRegistration: {
    component: SocialMediaAccountRegistration,
    path: "/SocialMediaAccountRegistration"
  },
  Adminconsole: {
    component: Adminconsole,
    path: "/Adminconsole"
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: "/PhoneNumberInput"
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: "/AdditionalDetailForm"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },
  Rolesandpermissions: {
    component: Rolesandpermissions,
    path: "/Rolesandpermissions"
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: "/Adminconsole2"
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: "/EmailAccountLoginBlock"
  },
  NavigationMenu: {
    component: NavigationMenu,
    path: "/NavigationMenu"
  },
  Rolesandpermissions2: {
    component: Rolesandpermissions2,
    path: "/Rolesandpermissions2"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },

  Analytics: {
    component: Analytics,
    path: "/Analytics"
  },
  AnalyticsChart: {
    component: AnalyticsChart,
    path: "/AnalyticsChart"
  },
  AnalyticsChart2: {
    component: AnalyticsChart2,
    path: "/AnalyticsChart2"
  },
  AnalyticsChart4: {
    component: AnalyticsChart4,
    path: "/AnalyticsChart4"
  },
  Home: {
    component: LandingPage,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },
  WhiteboardCollaboration: {
    component: WhiteboardCollaboration,
    path: '/WhiteboardCollaboration'
  },
  Whiteboard: {
    component: Whiteboard,
    path: '/Whiteboard',
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {

  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    const url = window.location.pathname;

    const isPaddingLeft = (url !== "/") ? "202px" : "0px"
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        {/* <TopNav /> */}
        {
          (url !== "/") && <Route render={props => <Sidebar {...props} />} />
        }

        <div style={{ paddingLeft: isPaddingLeft, }}>
          {WebRoutesGenerator({ routeMap })}
          <ModalContainer />
        </div>
      </View>
    );
  }
}

export default App;
