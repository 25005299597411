import React from "react";

// Customizable Area Start
import { Tooltip } from "@mui/material";
// Customizable Area End

import AnalyticsController, {
  Props
} from "./AnalyticsController.web";

//@ts-ignore
import {acdata} from "./acdata"

//@ts-ignore
import { sankey as d3Sankey } from "d3-sankey";

//@ts-ignore
import { Spring } from 'react-spring';



export default class AnalyticsChart extends AnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  radiusTUAS = (y0:any,y1:any,turnY:any,turnPercentageY:any) =>  {
    let z0,z1,radius2,y00,turnYY;
    z0 = Math.trunc(y0)
    z1 = Math.trunc(y1)
    radius2=7
    if(z1>z0){
      y00 = y0 + radius2;
      turnYY = turnY - radius2;
    } else if ((z1<z0)){
      y00 = y0 - radius2;
      turnYY = turnY + radius2;
    } else {
      y00 = y0
      turnYY = turnY
    }
    return {y00,turnYY}
  }

  springF = (link:any) => {
    const turnPercentageX = link.xP;
    const turnPercentageY = link.yP+link.linkX1;
          const turnX = link.source.x1 + (link.target.x0 - link.source.x1) * (turnPercentageX / 100);
          const turnY = 300 * (turnPercentageY / 100);
          const radius = 7;
          const {y00,turnYY} = this.radiusTUAS(link.y0,link.y1,turnY,turnPercentageY)
          let path;
          
          if(link.turn4){
              path = `M ${link.source.x1},${link.y0 + link.linkY0}
                L ${turnX - radius},${link.y0 + link.linkY0}
                Q ${turnX},${link.y0 + link.radiusX0} ${turnX},${link.y0 + link.radiusY0 + link.radiusX0 + 7}
                Q ${turnX},${link.y0 + link.radiusX0 + 12} ${turnX+20},${link.y0 + link.radiusY0 + link.radiusX0 + 10}
                L ${turnX + 120 - radius},${link.y0 + link.radiusY0 + link.radiusX0 + 10}
                Q ${turnX + 120},${link.y0 + link.radiusY0 + 10} ${turnX + 120},${link.y0}
                L ${turnX + 120},${turnYY - link.radiusY0}
                Q ${turnX + 120},${turnY} ${turnX + 120 + radius},${turnY}
                L ${link.target.x0 - link.radiusX0},${turnY}
                Q ${link.target.x0},${turnY} ${link.target.x0},${link.y1 + link.linkY1 - link.radiusY0}
                L ${link.target.x0},${link.y1 + + link.linkY1}`;
      
          } else {
            path = `M ${link.source.x1},${link.y0+link.linkY0}
              L ${turnX - radius},${link.y0+link.linkY0}
              Q ${turnX},${link.y0+link.radiusX0} ${turnX},${y00+link.radiusY0+link.radiusX0} 
              L ${turnX},${turnYY-link.radiusY0} 
              Q ${turnX},${turnY} ${turnX + radius},${turnY} 
              L ${link.target.x0},${turnY} 
              Q ${link.target.x0},${turnY} ${link.target.x0}
              L ${link.target.x0},${link.y1}`; 
          }
  
    

        

          const generatePosition = (_:any, i:any) => {
            let x,y
            if(i == 0){
              x = y = i
            } else {
              x = link.cXaxis[i-1] 
              y = link.cYaxis[i-1] == undefined ? link.y0 : link.y0 + link.cYaxis[i-1]
            }
            return { x, y };
          }

          const numberOfCircles = link.cPosition;
          const circlePositions = Array.from({ length: numberOfCircles }, generatePosition);
  
       return {path:path,circlePositions:circlePositions}   
  }

  renderSpringContent(link:any, index:any) {
    return (
      <Spring
        key={index}
        from={{ percent: 0 }}
        to={{ percent: 1 }}
        config={{ duration: 1000 }}
      >
        {(props:any) => {
          const {path,circlePositions} = this.springF(link)
  
          return (
            <>
              {circlePositions.slice(1).map((position:any, idx:any) => (
                <Tooltip
                  key={idx}
                  title={"Point: " + String(index) + idx}
                  placement="top"
                  arrow
                  style={{
                    fontSize: '16px',
                    padding: '20px',
                    width: '200px',
                    height: '100px',
                    cursor: "pointer"
                  }}
                >
                  <circle
                    key={idx}
                    cx={position.x}
                    cy={position.y}
                    r={3}
                    fill={link.color}
                  />
                </Tooltip>
              ))}
  
              <path
                d={path}
                style={{
                  fill: 'none',
                  stroke: link.color,
                }}
              />
            </>
          );
        }}
      </Spring>
    );
  }


renderGrid = (
  <g>
    {Array.from(Array(11).keys()).map((_, index) => (
      <line
        key={`v-line-${index+3}`}
        x1={(index + 1) * 85}
        y1="0"
        x2={(index + 1) * 85}
        y2="300"
        stroke="#ccc"
        strokeWidth="0.5"
        strokeDasharray="2"
      />
    ))}
  </g>
);


customSort = (a:any, b: any): number => a.index - b.index;

  // Customizable Area End

  render() {
    const { nodes, links } = d3Sankey()
      .nodeWidth(7)
      .nodePadding(20)
      .nodeSort(this.customSort)
      .linkSort(this.customSort)
      .extent([[1, 1], [500, 300]])(acdata);
      
      nodes.forEach((node:any,index:any) => {
        node.x0 = node.nodeX0;
        node.y0 = node.nodeY0;
        node.height = node.nodeHeight;
      });

      

      
    return (
      // Customizable Area Start
      <div style={webStyle.mainWrapper}>
        {!this.state.chat.length && <div style={webStyle.scndWrapper} >
          <div style={webStyle.textButtonDiv}>
            <div style={webStyle.textButton}>Analytics</div>
          </div>
          <div style={webStyle.flexdisplay} >
              
              <svg  
                width="100%"
                height="130%"
                viewBox={`0 0 ${510} ${255}`}
                xmlns="http://www.w3.org/2000/svg"
                >
                  {this.renderGrid}
                <g  
                    style={{
                      width: '100%',
                      height: '100%',
                    }}
                >
                {links.map((link:any, index:any) => this.renderSpringContent(link, index))}
                </g>
                <g>
                  {nodes.map((node:any) => (
                    <rect
                      key={`node:${node.index}`}
                      x={node.x0}
                      y={node.y0}
                      height={node.height}
                      width={7}
                      style={{
                        fill: node.color
                      }}
                    />
                  ))}
                </g>
              </svg>
          </div>
        </div>}
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start


const webStyle = {
  chart: {
    width: "100%",
    height: "110%"
  },
  mainWrapper: {
    display: "flex",
    justifyContent: 'center',
    width: "auto",
    minHeight: "100vh",
    background: 'linear-gradient(135deg, #F4EEFC 0%, #FAEEEC 100%)',
    blur: "100px",
    fontFamily: "'Rubik', sans-serif",
  },
  scndWrapper: {
    width: "100%",
    marginTop: '20px'

  },
  textButtonDiv: {
    paddingInlineStart: '12px',
    height: 'auto',
    alignItems: 'center',
    lineHeight: '40px',
  },
  textButton: {
    color: 'var(--Base-Blue-grey-10, #3C3E49)',
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: 500,
    fontFamily: "Rubik",
  },
  scndScreenContainer: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    width: '80%',
    margin: '30px',
    marginTop: 'auto',
  },
  chatfromNatashaDiv: {
    background: "#ffffff",
    padding: "16px 20px 8px 20px",
    borderRadius: "0px 16px 16px 16px"
  },
  flexdisplay:{
    paddingLeft:"30px",
    paddingBottom:"200px",
    marginTop:"-100px",
  } 
  
};
// Customizable Area End
