//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// import ImagePicker from "react-native-image-crop-picker";
import { Alert } from "react-native";
const CategoryData = [
  {
    idss: 0,
    title: "User 1",
    value: 'user_1'
  },
  {
    idss: 1,
    title: "User 2",
    value: 'user_2'

  },
];

interface Imagetype {
  uris: string;
  width: number;
  height: number;
}
interface CategoryType {
  idss: number;
  title: string;
  value: string;
}

interface ProductItem  {
  id: string;
  type: string;
  attributes: {
    title: string;
    description: string;
    status: boolean;
    price: number;
    user_type: string;
    quantity: string;
    created_at: string;
    updated_at: string;
    images: [
      {
        id: number;
        url: string;
        type: string;
        filename: string;
      }
    ];
  };
}
// Customizable Area End

export const configJSON = require("./config");


export interface Props {
  // Customizable Area Start
  navigation: object;
  idContent: string | number;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  catagorydata: CategoryType[];
  showCategory: boolean;
  category: CategoryType;
  showModel: boolean;
  images: Imagetype[];
  imagesWeb: File[];
  baseImages: string[];
  title: string;
  description: string;
  price: string;
  quantity: string;
  userDataList:ProductItem[]


  selectedMessage: string;
  txtInputValue: string;
  suggestions: any[];
  inputState: any,
  chat: any[],
  natashaTyping: boolean,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  idManagement: number | string;
  // Customizable Area End
}

export default class ContentManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  userDataListCall:string|Message=''
  addDataCall:string|Message=''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      catagorydata: CategoryData,
      showCategory: false,
      category: {
        idss: 0,
        title: "User 1",
      value:'user_1'
      },
      showModel: false,
      images: [],
      imagesWeb:[],
      baseImages: [],
      title: "",
      description: "",
      price: "",
      quantity: "",
      userDataList:[],
      
      selectedMessage: "",
      txtInputValue: "",
      suggestions: ["How many apps did Builder create in the last month?", "Which app has taken the shortest amount of time to build?", "Which are our biggest customers?", "What's the most popular feature?"],
      inputState: "",
      natashaTyping: true,
      chat: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.userDataListCall !== null &&
      this.userDataListCall ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson1 = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorResponse1 = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson1) {
        this.setState({
           userDataList: responseJson1.data,
          loading: false,
        });
      } else {
        this.setState({
          loading: false,
        });
        this.parseApiErrorResponse(responseJson1);
      }
      this.parseApiCatchErrorResponse(errorResponse1);
    }
    else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.addDataCall !== null &&
      this.addDataCall ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson2 = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorResponse2 = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson2) {
        Alert.alert(configJSON.success)
        this.setState({
          loading: false,
          showModel:false
        });
        this.getUserDataListApi()
      } else {
        this.setState({
          loading: false,
        });
        this.parseApiErrorResponse(responseJson2);
      }
      this.parseApiCatchErrorResponse(errorResponse2);
    }

    
    // Customizable Area End
  }

  // Customizable Area Start

async componentDidMount() {
  this.getUserDataListApi()
/* istanbul ignore next */
  if(window.location.pathname === '/NatashaChatScreen'){
    const msg = localStorage.getItem("natashaChatMessage")
    this.setState({ chat: JSON.parse(msg) })
  }
}

  getUserDataListApi = async () => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userDataListCall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userListUrl+this.state.category.value
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  postAddDataApi=async()=>{
    const header = {
    };
    const formData = new FormData();
    formData.append("title", this.state.title)
    formData.append("description", this.state.description)
    formData.append("price", this.state.price);
    formData.append("quantity", this.state.quantity)
    formData.append("user_type", this.state.category.value)

   

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addDataCall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     configJSON.addDataUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;


  }

  showCategory = () =>
    this.setState({
      showCategory: !this.state.showCategory,
    });

  onSelectCategory = (item: CategoryType) => {
    this.setState({ category: item, showCategory: false },()=>{
      this.getUserDataListApi()
    });
  };

  addNewProduct = () => this.setState({ showModel: true });

  hideModal = () => this.setState({ showModel: false });


  onChangeTitle = (text: string) => {
    this.setState({ title: text });
  };

  onChangeDescription = (text: string) => {
    this.setState({ description: text });
  };

  onChangePrice = (text: string) => {
    this.setState({ price: text });
  };

  onChangeQuantity = (text: string) => {
    this.setState({ quantity: text });
  };

  onSelectMessage(value: string) {
    const messages = this.state.chat;
    messages.push({ message: value });
    localStorage.setItem("natashaChatMessage", JSON.stringify(messages))
    
    this.setState({ chat: messages }, () => {
      this.props.navigation.navigate("NatashaChatScreen")
    })


  }
  inputChange(e: any) {
    e.preventDefault()
    this.setState({ inputState: e.target.value })
  }
  formSubmit(e: any) {
    e.preventDefault()
    const msg = [{
      message : this.state.inputState
    }]
    localStorage.setItem("natashaChatMessage", JSON.stringify(msg))
    this.setState({ chat: [{ message: this.state.inputState }] }, () => {
      this.props.navigation.navigate("NatashaChatScreen")
    })
    this.setState({ inputState: "" })
  }
  onSubmitChat(e:any){
    e.preventDefault()
    const msg = this.state.chat;
    msg.push({ message: this.state.inputState });
    this.setState({ chat: msg },()=>{
      this.setState({ inputState: "" })
      this.natashaTyping()
    })
  }

  natashaTyping(){
    this.setState({ natashaTyping: false })
    setTimeout(/* istanbul ignore next */() => {
      this.setState({ natashaTyping: true })
    }, 1000);
  }
  // Customizable Area End
}
