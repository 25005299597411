import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { defaultIcon } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  banner: any;
  features: any[];
  loader: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  addDataCall: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      banner: null,
      features: [],
      loader: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const landingPageMsg: Message = new Message(
      getName(MessageEnum.AnalyticsScreenMessage)
    );
    landingPageMsg.addData(
      getName(MessageEnum.AnalyticsScreenMessageName),
      "landingpage"
    );
    if(this.state.hasOwnProperty('userToken')) this.send(landingPageMsg);
    this.getContentManagement();
    // Customizable Area End
  }

  landingResponse = (responseJson: any) => {
    const bannerData = responseJson.find(
      (item: any) => item.section === "Banner"
    )?.data.data;
    const banner = bannerData && bannerData[bannerData.length - 1];
    const features = responseJson.find(
      (item: any) => item.section === "Key Features"
    )?.data.data;
    this.setState({ banner, features });
    this.setState({ loader: false });
  };

  Login = (baseURL: any) => {
    const REACT_APP_LOGIN_PATH =
      "https://staging.accounts.builder.ai/oauth/authorize/?client_id=TrQFIwNuXMkAYFWs_lTpCEp1FkXbsuF6AOPROQlpdnw&redirect_uri=https://builderanalytics-404910-ruby.b404910.dev.eastus.az.svc.builder.cafe/users/auth/aaa/callback&response_type=code&scope=oauth2_uids+openid&state=BuilderAnayltics";

    const REACT_APP_LOGIN_PATH_STAGE =
      "https://staging.accounts.builder.ai/oauth/authorize/?client_id=zGuET-LNwFoJJF3Hxfr1WCv41FiY2Ec2wirgX--Gse4&redirect_uri=https://builderanalytics-404910-ruby.b404910.stage.eastus.az.svc.builder.ai/users/auth/aaa/callback&response_type=code&scope=oauth2_uids+openid&state=BuilderAnayltics";

    const REACT_APP_LOGIN_PATH_UAT =
      "https://accounts.builder.ai/oauth/authorize/?client_id=nueYAqpvUVUYQIg2EHu59HJS3t-q-iC_HuKmmRt3UlU&redirect_uri=https://builderanalytics-404910-ruby.b404910.uat.eastus.az.svc.builder.ai/users/auth/aaa/callback&response_type=code&scope=oauth2_uids+openid&state=BuilderAnayltics";

    const REACT_APP_LOGIN_PATH_PROD = "https://accounts.builder.ai/oauth/authorize/?client_id=RvvhkOWSRJeu1RoOx8jNv3v3RQ7auRWL0C0qbNyCLQQ&redirect_uri=https://builderanalytics-404910-ruby.builder-analytics.prod.eastus.az.svc.builder.ai/users/auth/aaa/callback&response_type=code&scope=oauth2_uids+openid&state=BuilderAnayltics";  

    if (baseURL.includes(".prod.")) {
      return REACT_APP_LOGIN_PATH_PROD;
    } else if (baseURL.includes(".stage.")) {
      return REACT_APP_LOGIN_PATH_STAGE;
    } else if (baseURL.includes(".uat.")) {
      return REACT_APP_LOGIN_PATH_UAT;
    } else { 
      return REACT_APP_LOGIN_PATH;
    }  
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.addDataCall) {
        if (responseJson) {
          this.optimizedLandingResponse(responseJson);
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  optimizedLandingResponse = (responseJson: any) => {
    let banner = null;
    let features = null;

    for (const item of responseJson) {
      if (item.section === "Banner" && item.data?.data) {
        const bannerData = item.data.data;
        banner = bannerData[bannerData.length - 1];
      }
      if (item.section === "Key Features" && item.data?.data) {
        features = item.data.data;
      }

      if (banner && features) break;
    }
    console.log(banner);
    console.log(features);
    this.setState({ banner, features, loader: false }); 
  };

  defaultImageError = (e: any) => {
    e.target.src = defaultIcon;
  };

  getContentManagement = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addDataCall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addDataUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  sendAnalyticTrack = (trackName: string, featureID: string) => {
    if(!this.state.hasOwnProperty('userToken')) return;
    return () => {
      const msg: Message = new Message(
        getName(MessageEnum.AnalyticsTrackMessage)
      );
      msg.addData(getName(MessageEnum.AnalyticsTrackMessageName), trackName);
      msg.addData(getName(MessageEnum.AnalyticsTrackMessageProperties), {
        "BC_ID": "404910",
        "FEATURE_ID": featureID,
      })
      this.send(msg);
    }
  }
}
