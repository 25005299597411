import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import firebase from "firebase";

// Customizable Area Start
import React from "react";
import queryString from 'query-string';
import * as d3 from 'd3';
import { sankey as d3Sankey, sankeyLinkHorizontal } from "d3-sankey";
import "./Analytics.css"
import moment from "moment";
import { debounce } from "@mui/material";

export type Data = {
  value: string;
  name: string;
}
type Range<T> = [T, T];
type DateVal = Date | null
type DeweyEventResponse = {
  data: DeweyEvent[];
}
type DeweyEvent = {
  id: number;
  dewey_code: string;
  event_name: string;
  created_at: string;
  updated_at: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  searchValue: string;
  selectCustomer: number;
  selectCustomerOpenClose: boolean;
  tabValue: number;
  selectedMessage: string;
  txtInputValue: string;
  suggestions: any[];
  inputState: any,
  natashaTyping: boolean,

  mouseX: number,
  buttonTracker: any[],
  rootNode: null,
  pathLinks: any[],
  circleLinks: any[],
  textLinks: any[],
  chat: any[]
  sChartWidth: any,
  sChartData: any,
  chartReady: boolean,
  columnWidth: number,
  chartStage: any,
  chartStageBox: any,
  svg: any,
  availableColumns: any,
  unfilteredStages: any,
  unfilteredBoxes: any,
  unfilteredFlows: any,
  stages: any,
  boxes: any,
  flows: any,
  boxNodes: any,
  stageNodes: any,
  flowNodes: any,
  token: string,
  buildcardStage: any,
  buildcardBox: any,
  buildcardFlow: any,
  buildcardNodes: any,
  eventNames: Map<string,string>,
  currentBuildcard: string,

  countries: any[],
  isDropdownOpen: boolean,

  popoverId: string,
  popoverOpen: boolean,
  anchorEl: any,

  calendarAnchorEl: Element | null;
  calendarPopover: boolean;


  newestPopId:string,
  newestPopverOpen:boolean,
  newsetAnchorEl:any,
  stageData: Data[],
  selectedStage: string[],
  subStageData: Data[],
  selectedSubStage: string[],

  flowData: Data[],
  selectedFlowData: string[],

  boxData: Data[],
  selectedSurfaceData: string[],

  subFlowData: Data[],
  selectedSubFlowData: string[],

  surfaceVariantsData: Data[],
  selectedSurfaceVariantsData: string[],

  campaignData: Data[],
  selectedCampaignData: string[],

  selectedDates: DateVal[] | Range<DateVal>
  showDatePicker: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AnalyticsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getsChartData: string = "";
  public chartRef2: React.RefObject<SVGSVGElement>;
  getUniqueStagesApiCallId: string = "";
  getUniqueStageBoxesApiCallId: string = "";
  getUniqueStageBoxFlowsApiCallId: string = "";
  getBoxesFilterApiCallId: string = "";
  getFlowsFilterApiCallId: string = "";
  searchBuildcardApiCallId: string = "";
  searchBuildcardBoxApiCallId: string = "";
  searchBuildcardFlowApiCallId: string[] = [];
  getEventNamesApiCallId: string = "";
  
  public chartRef3: React.RefObject<SVGSVGElement>;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      searchValue: "",
      selectCustomer: 1,
      selectCustomerOpenClose: false,
      tabValue: 1,
      selectedMessage: "",
      txtInputValue: "",
      suggestions: ["How many apps did Builder create in the last month?", "Which app has taken the shortest amount of time to build?", "Which are our biggest customers?", "What's the most popular feature?"],
      inputState: "",
      natashaTyping: true,
      mouseX: 0,
      buttonTracker: [],
      rootNode: null,
      pathLinks: [],
      circleLinks: [],
      textLinks: [],
      chat: [],
      sChartWidth: 1350,
      sChartData: null,
      chartReady: false,
      columnWidth: Math.round(1350/ 8),
      chartStage: '',
      chartStageBox: '',
      svg: null,
      availableColumns: 8,
      stages: [],
      boxes: [],
      flows: [],
      unfilteredStages: [],
      unfilteredBoxes: [],
      unfilteredFlows: [],
      stageNodes: [],
      boxNodes: [],
      flowNodes: [],
      buildcardStage: [],
      buildcardBox: [],
      buildcardFlow: [],
      buildcardNodes: [],
      token: '',
      eventNames: new Map<string,string>(),
      currentBuildcard: '',

      selectedDates: [],
      showDatePicker: false,

      
      countries: ["Country name 1", "Country name 2", "Country name 3", "Country name 4","Country name 5"],
      isDropdownOpen: false,

      popoverId: "id",
      popoverOpen: false,
      anchorEl: null,

      calendarAnchorEl: null,
      calendarPopover: false,

      newestPopId:"newPopId",
      newestPopverOpen:false,
      newsetAnchorEl:null,
      stageData:[],
      selectedStage: [],
      subStageData: [
        {
          name: 'Offsite',
          value: 'A1',
        },
        {
          name: 'Website interaction',
          value: 'A2',
        },
        {
          name: 'Sign up',
          value: 'A3',
        },
        {
          name: 'Lead assessment',
          value: 'A4',
        },
      ],
      selectedSubStage: [],

      flowData: [],
      selectedFlowData: [],

      boxData: [],
      selectedSurfaceData: [],

      subFlowData: [
        {
          value: 'A1.1.1',
          name: 'Facebook consideration Ad displayed',
        },
        {
          value: 'A1.1.2',
          name: 'Facebook conversion Ad displayed',
        },
        {
          value: 'A1.1.3',
          name: 'Facebook remarketing Ad displayed',
        },
      ],
      selectedSubFlowData: [],

      surfaceVariantsData: [
        {
          value: 'A',
          name: 'Stage',
        },
        {
          value: 'B',
          name: 'Stage+box',
        },
        {
          value: 'C',
          name: 'Flow',
        },
        {
          value: 'D',
          name: 'Flow subcategory',
        },
        {
          value: 'E',
          name: 'Surface',
        },
        {
          value: 'F',
          name: 'Campaign/Action Variants',
        },
        {
          value: 'G',
          name: 'Surface Variants',
        },
      ],
      selectedSurfaceVariantsData: [],

      campaignData: [
        {
          value: 'A',
          name: 'Option 1',
        },
        {
          value: 'B',
          name: 'Option 2',
        },
        {
          value: 'C',
          name: 'Option 3',
        },
        {
          value: 'D',
          name: 'Option 4',
        },
      ],
      selectedCampaignData: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.chartRef2 = React.createRef()
    this.chartRef3 = React.createRef()
    // Customizable Area End
    if (firebase.apps.length !== 0) {
      const defaultAnalytics = firebase.app().analytics();
      defaultAnalytics.logEvent("Analytics::Web::Load");
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if(apiRequestCallId === this.getUniqueStagesApiCallId){
        this.setState({unfilteredStages: responseJson.stages})
        this.setState({stages: this.filterStages(responseJson.stages)})
        const stagedata = responseJson.stages.map((d:any) => ({
          value: d.stage,
          name: d.stage,
        }));
        this.setState({stageData: stagedata})
      }
      this.handleGetNamesAPIResponse(apiRequestCallId, responseJson);
      this.handleSearchBuildcardAPIResponse(apiRequestCallId, responseJson, message);
      this.handleSearchBuildcardBoxAPIResponse(apiRequestCallId, responseJson);
      this.handleSearchBuildcardFlowAPIResponse(apiRequestCallId, responseJson);
      
      
      this.handleGetUniqueStageBoxesApiResponse(apiRequestCallId, responseJson);
      this.handleGetUniqueStageBoxFlowsApiResponse(apiRequestCallId, responseJson);
      

      this.handleGetBoxesFilter(apiRequestCallId, responseJson);
      

      if(apiRequestCallId === this.getFlowsFilterApiCallId){
        const data = responseJson.flow.map((d:string) => ({
          value: d,
          name: d,
        }));
        this.setState({flowData: data})
      }

      if (apiRequestCallId === this.getsChartData) {
        if (responseJson) {
          console.log(responseJson,'reee')
          this.setState({sChartData:responseJson});
          this.drawChart(responseJson);
          console.log(responseJson);
        }
        this.parseApiCatchErrorResponse(errorReponse);
        console.log(this.state.sChartData,'rrrrrrrr')
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  async doButtonPressed() {
    const defaultAnalytics = firebase.app().analytics();
    defaultAnalytics.logEvent("Analytics::Web::button_clicked");
  }

  // Customizable Area Start
  
  
async componentDidMount() {
  
  
  this.getsChart();
  this.drawChart([])
}

// handleChange(selectedDayRange:any) {
//   this.setState({ selectedDayRange : selectedDayRange});
// }
  handleSearchChange = (event: any) => {
    const value = event.target.value;
    this.setState({ searchValue: event.target?.value as string });
    this.setState({buildcardBox: [], buildcardFlow: [], buildcardStage: [], buildcardNodes: [], chartStage: '', chartStageBox: ''})
    if(value === ""){
      this.getUniqueStages(this.state.token)
    }else this.handleSearchChangeAPI(value);
  }
  handleSearchChangeAPI = debounce((value: string)=>{
    this.searchBuildCard(this.state.token, value);
  }, 500)
  handleGetNamesAPIResponse = (apiRequestCallId: string, responseJson: DeweyEventResponse)=>{
    if(apiRequestCallId === this.getEventNamesApiCallId){
      let map = new Map<string, string>()
      responseJson.data.reduce((acc,item)=>{
        acc.set(item.dewey_code, item.event_name)
        return acc
      },map)
      this.setState({eventNames: map})
    }

  }
  handleSearchBuildcardBoxAPIResponse = (apiRequestCallId: string, responseJson: any)=>{
    if(apiRequestCallId === this.searchBuildcardBoxApiCallId){
      
      if(responseJson.boxes.length < 1) {
        this.getUniqueStages(this.state.token);
        return;}
         let bcid = responseJson.boxes[0].buildcard_id;
        
        this.setState((prev)=>{return {buildcardBox: [...prev.buildcardBox, ...responseJson.boxes] }}, ()=>{
          for(let box of responseJson.boxes){
            this.searchBuildCardStageBoxFlows(this.state.token,box.stage, box.box, bcid)
          }
          this.drawBuildcardBox(bcid)
        })
  
        
      
    }
  
  }
  handleSearchBuildcardFlowAPIResponse = (apiRequestCallId: string, responseJson: any)=>{
    if(this.searchBuildcardFlowApiCallId.includes(apiRequestCallId)){
        let bcid = responseJson.flows[0].buildcard_id;
        this.setState((prev)=>{return {buildcardFlow: [...prev.buildcardFlow, ...responseJson.flows] }}, ()=>{
          this.drawBuildcardFlow(bcid)
        })
      
    }
  
  }
  handleGetUniqueStageBoxesApiResponse = (apiRequestCallId: string, responseJson: any) => {
    if(apiRequestCallId === this.getUniqueStageBoxesApiCallId){
      this.setState({unfilteredBoxes: responseJson.boxes})
      this.setState({boxes: this.filterBoxes(responseJson.boxes)})
    }
  }
  handleGetUniqueStageBoxFlowsApiResponse = (apiRequestCallId: string, responseJson: any) =>{
    if(apiRequestCallId === this.getUniqueStageBoxFlowsApiCallId){
      this.setState({unfilteredFlows: responseJson.flows})
      this.setState({flows: this.filterFlows(responseJson.flows)})
    }
  }
  handleGetBoxesFilter = (apiRequestCallId: string, responseJson: any) =>{
    if(apiRequestCallId === this.getBoxesFilterApiCallId){
      const boxdata = responseJson.box.map((d:string) => ({
        value: d,
        name: d,
      }));
      this.setState({boxData: boxdata})
    }
  }
  handleSearchBuildcardAPIResponse = (apiRequestCallId: string, responseJson: any, message: Message)=>{

    if(apiRequestCallId === this.searchBuildcardApiCallId){
      
      if(responseJson.stages.length < 1) {
        this.getUniqueStages(this.state.token);
        return;}
      let bcid = responseJson.stages[0].buildcard_id;

      console.log('BCID')
      console.log(bcid);
      const nodes = responseJson.stages.map((d:any, index:any) => ({
        ...d,
        nX0: index * (35 + 10),
        nX1: index * (35 + 10) + 35,
        nY0: 90,
        nY1: 90+50,
        column: index,
        className: "stages",
        name: d.stage,
        type: 'buildcard',
        dewey_code: d.stage,
       }))
      this.setState({stageNodes: nodes, currentBuildcard: bcid}, ()=>{
        this.drawBuildcardStage(nodes, bcid, responseJson)
      })
      
    }
  }
  removeAllBuildcardDrawings = () => {
    this.state.svg.selectAll(`.stageslinks`).remove();
    this.state.svg.selectAll(`.stageboxeslinks`).remove();
    this.state.svg.selectAll(`.stageboxflowslinks`).remove();
    this.state.svg.selectAll(`.stageboxflowpercentagetext`).remove();
    this.state.svg.selectAll(`.stageboxpercentagetext`).remove();
    this.state.svg.selectAll(`.stagepercentagetext`).remove();
    this.state.svg.selectAll(`.stageeventnametext`).remove();
    this.state.svg.selectAll(`.stageboxeventnametext`).remove();
    this.state.svg.selectAll(`.stageboxfloweventnametext`).remove();
  }
  drawBuildcardStage = (nodes: any, buildcardId: string, responseJson: any)=> {
    if(this.state.searchValue!= buildcardId) return;
    this.drawNodes(this.state.svg, nodes, this.state.availableColumns, this.state.columnWidth, 'stages')
      this.drawBuildcardPercentage(nodes, 'stagebuildcardtext')
      this.drawEventNameText(nodes, 'stagebuildcardnametext')
      this.removeAllBuildcardDrawings()

      for(let stage of responseJson.stages){
        this.searchBuildCardStageBoxes(this.state.token, stage.stage, this.state.searchValue)
      }
  }
  drawBuildcardBox = (buildcardId: string)=> {
    if(this.state.searchValue != buildcardId) return;
    const nodes = this.state.buildcardBox.map((d:any, index:any) => ({
      ...d,
      nX0: index * (35 + 10),
      nX1: index * (35 + 10) + 35,
      nY0: 290,
      nY1: 290 + 50,
      column: index,
      className: "stageboxes",
      name: d.stage+"."+d.box,
      type: 'buildcard',
      dewey_code: d.stage+"."+d.box,
     }))
     this.setState({boxNodes: nodes})
    const stageNodes = this.state.stageNodes;
    this.adjustWidth(nodes);
    if(this.state.searchValue!= buildcardId) return;
    this.drawNodes(this.state.svg, nodes, this.state.availableColumns, this.state.columnWidth, 'stageboxes');
    this.drawEventNameText(nodes, 'boxbuildcardnametext')
    this.drawBuildcardPercentage(nodes, 'boxbuildcardtext')
    const className = 'buildcardbox';
    this.state.svg.selectAll(`.${className}`).remove()
     for(let element of nodes){
      
      const node = stageNodes.find((val:any) => val.stage === element.stage);
      
      this.drawVerticalLink(node, element, className);
    }

  }
  drawBuildcardFlow = (buildcardId: string)=> {
    if(this.state.searchValue != buildcardId) return;
    const boxNodes = this.state.boxNodes;
      const nodes = this.state.buildcardFlow.sort((a: any, b: any)=>a.box - b.box).map((d:any, index:any) => ({
        ...d,
        nX0: index * (35 + 10),
        nX1: index * (35 + 10) + 35,
        nY0: 500,
        nY1: 500 + 50,
        column: index,
        className: "stageboxflows",
        name: d.stage+'.'+d.box+d.flow,
        type: 'buildcard',
        dewey_code: d.stage+'.'+d.box+'.'+d.flow,
      }))


      this.adjustWidth(nodes);
      if(this.state.searchValue != buildcardId) return;
      this.drawNodes(this.state.svg, nodes, this.state.availableColumns, this.state.columnWidth, 'stageboxflows');
      this.drawEventNameText(nodes, 'flowbuildcardnametext')
      this.drawBuildcardPercentage(nodes, 'flowbuildcardtext')

      const className = 'buildcardflow';
      this.state.svg.selectAll(`.${className}`).remove()
     for(let element of nodes){
      const node = boxNodes.find((val:any) => val.box === element.box);
      this.drawVerticalLink(node, element, className);
    }
    this.drawLinks(nodes, className+'links');

  }
  handleSelect = (event: any) => {
    this.setState({ selectCustomer: event.target?.value as number });
  }

  handleSelectClose = () => {
    this.setState({ selectCustomerOpenClose: false });
  };

  handleSelectOpen = () => {
    this.setState({ selectCustomerOpenClose: true });
  };

  handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ tabValue: newValue })
  };

  handleCountryModalClose = () => {
    this.setState({
      anchorEl: null,
      popoverOpen: false,
    });
  }
  filterStages = (data: any) => {
    return this.state.selectedStage.length > 0 ? data.filter((data: any) => this.state.selectedStage.includes(data.stage)) : data;
  }
  filterBoxes = (data: any) => {
    return this.state.selectedSurfaceData.length > 0 ? data.filter((box: any) => this.state.selectedSurfaceData.includes(box.box)) : data;
  }
  filterFlows = (data: any) => {
    return this.state.selectedFlowData.length > 0 ? data.filter((flow: any) => this.state.selectedFlowData.includes(flow.flow)) : data;
  }
  handleOpenCountriesModal = (event:any) => {
    this.setState(
      {
        anchorEl: event.currentTarget,
      },
      () => {
        this.setState({
          popoverOpen: Boolean(this.state.anchorEl),
        });
      }
    );
  }
  
  

  toggleDropdown = () => {
    this.setState(prevState => ({
      isDropdownOpen: !prevState.isDropdownOpen
    }));
  };
  getUniqueStages(token: string): boolean {
    const webHeader = {
      'Content-Type': 'application/json',
      'token': `${token}`,
    };

    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //store the call id?
    this.getUniqueStagesApiCallId = webRequestMessage.messageId;

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.analyticsUniqueStages
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);

    return true;
  }
  searchBuildCard(token: string, buildcardId: string): boolean {
    const webHeader = {
      'Content-Type': 'application/json',
      'token': `${token}`,
    };

    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //store the call id?
    this.searchBuildcardApiCallId = webRequestMessage.messageId;

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.analyticsUniqueStages+"?buildcard_id="+buildcardId
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);

    return true;
  }
  
  searchBuildCardStageBoxes(token: string, stage: string, buildcardId: string): boolean {
    const webHeader = {
      'Content-Type': 'application/json',
      'token': `${token}`,
    };

    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //store the call id?
    this.searchBuildcardBoxApiCallId = webRequestMessage.messageId;

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.analyticsUniqueBoxes + '?stage='+stage+'&buildcard_id='+buildcardId
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);

    return true;
  }

  getUniqueStageBoxes(token: string, stage: string): boolean {
    const webHeader = {
      'Content-Type': 'application/json',
      'token': `${token}`,
    };

    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //store the call id?
    this.getUniqueStageBoxesApiCallId = webRequestMessage.messageId;

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.analyticsUniqueBoxes + '?stage='+stage
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);

    return true;
  }

  getEventNames(token: string): boolean {
    const webHeader = {
      'Content-Type': 'application/json',
      'token': `${token}`,
    };

    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //store the call id?
    this.getEventNamesApiCallId = webRequestMessage.messageId;

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.analyticsEventNames
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);

    return true;
  }
  getUniqueStageBoxFlows(token: string, stage: string, box: string): boolean {
    const webHeader = {
      'Content-Type': 'application/json',
      'token': `${token}`,
    };

    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUniqueStageBoxFlowsApiCallId = webRequestMessage.messageId;

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.analyticsUniqueFlows + '?stage='+stage+'&box='+box
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);

    return true;
  }
  searchBuildCardStageBoxFlows(token: string, stage: string, box: string, buildcardId: string): boolean {
    const webHeader = {
      'Content-Type': 'application/json',
      'token': `${token}`,
    };

    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.searchBuildcardFlowApiCallId.push(webRequestMessage.messageId);

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.analyticsUniqueFlows + '?stage='+stage+'&box='+box+'&buildcard_id='+buildcardId
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);

    return true;
  }
  getBoxesFilter(token: string): boolean {
    const webHeader = {
      'Content-Type': 'application/json',
      'token': `${token}`,
    };

    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //store the call id?
    this.getBoxesFilterApiCallId = webRequestMessage.messageId;

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.analyticsBoxesFilter
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);

    return true;
  }
  getFlowsFilter(token: string): boolean {
    const webHeader = {
      'Content-Type': 'application/json',
      'token': `${token}`,
    };

    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //store the call id?
    this.getFlowsFilterApiCallId = webRequestMessage.messageId;

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.analyticsFlowsFilter
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);

    return true;
  }
  
  getsChart = async () => {
      const query = queryString.stringify({
        chart_type:"sankey",
        per_page:100000
      })
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.getsChartData = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getsChartDataEndPoint + `?${query}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    };


  controlPoints = (x: any, y: any, x2: any, y2: any) => x < x2
  ? {
    cp1: { x: x + (x2 - x) / 3 * 2, y },
    cp2: { x: x + (x2 - x) / 3, y: y2 }
  }
  : {
    cp1: { x: x - (x - x2) / 3, y: 0 },
    cp2: { x: x2 + (x - x2) / 3, y: 0 }
  };



  drawHeaders = (svg: any, headers: any, availableColumns: number, columnWidth: number)=>{
    const group = svg.append('g').attr('class', 'headers');
    
  
    group.selectAll('rect')
    .style('z-index', 2)
    .data(headers)
    .join('rect')
    .attr('x', (d: any) =>  columnWidth * d.column)
    .attr('y', (d: any, index: any) =>  d.nY0) 
    .attr('height', (d: any) =>  d.nY1)
    .attr('width', (d: any) =>  columnWidth)
    .attr('stroke', '#E2E8F0')
    .attr('fill', '#F8FAFC')
    .attr('stroke-width', 1)
    
    group.selectAll('text')
    .data(headers)
    .enter()
    .append('text')
    .attr('x', (d:any) => columnWidth * d.column + columnWidth / 2)  // Center text horizontally
    .attr('y', (d:any) => d.nY0 + d.nY1 / 2 + 5) 
    .attr('text-anchor', 'middle')
    .attr('alignment-baseline', 'middle')
    .text((d:any)=>d.stage);     
  }
  drawBuildcardPercentage = (nodes: any, className: string)=>{
    const svg = this.state.svg;
    if(!svg) return;
    svg.selectAll(`.${className}`).remove();
    const group = this.state.svg.append('g').attr('class', className);
    const columnWidth = this.state.columnWidth;
    const padding = 20;
    group.selectAll('text')
    .data(nodes)
    .enter()
    .append('text')
    .attr('x', (d:any) => columnWidth * d.column + padding + 35/2)
    .attr('y', (d:any) => d.nY0 - 10) 
    .attr('text-anchor', 'middle')
    .attr('alignment-baseline', 'middle').attr('font-size', '10')
    .text((d:any)=>d.buildcards_reached_stage_percentage > 0 ? d.buildcards_reached_stage_percentage+'%': '');  
  }
  checkSVGIsNull = ()=>{
    return !this.state.svg ? true : false;
  }
  drawEventNameText = (nodes: any, className: string)=>{
    if(this.checkSVGIsNull())  return;
    this.state.svg.selectAll(`.${className}`).remove();
    const group = this.state.svg.append('g').attr('class', className);

    const columnWidth =  this.state.columnWidth;
    const padding =  20;

    group.selectAll('text')
    .data(nodes)
    .enter()
    .append('text')
    .attr('x', (d:any) => columnWidth * d.column + padding +35/2)
    .attr('y', (d:any) => d.nY0 - 25) 
    .attr('text-anchor', 'middle')
    .attr('alignment-baseline', 'middle').attr('font-size', '10')
    .text((d:any)=>{
      let name = this.state.eventNames.get(d.dewey_code)
      name = !name ? 'unknown' : name;
      if(name.length < 10) return name;
      else{
        return name.slice(0, 30)+'...';
      }
    })
  }
  drawNodes = (svg: any, nodes: any, availableColumns: number, columnWidth: number, className: string) => {
    if(svg === null) return;
    svg.selectAll(`.${className}`).remove();
    const group = svg.append('g').attr('class', className);

    const padding = 20;
  
    group.selectAll('rect')
    .style('z-index', 2)
    .data(nodes)
    .join('rect')
    .attr('x', (d: any) =>  columnWidth * d.column + padding)
    // .attr('x', (d: any) => d.column == 0 ? 5 : columnWidth * d.column)
    .attr('y', (d: any, index: any) =>  d.nY0) //for nY0 I can prolly dynamically generate it based on whether it is stage, stage + box, or stage + box + flow
    // .attr('y', (d: any, index: any) => d.column == 0 ? 5 + index * 75 : d.nY0) //for nY0 I can prolly dynamically generate it based on whether it is stage, stage + box, or stage + box + flow
    .attr('width', (d: any) =>  35)
    // .attr('width', (d: any) => d.column == 0 ? 25 : 35)
    .attr('height', (d: any) =>  d.nY1 - d.nY0)
    // .attr('height', (d: any) => d.column == 0 ? 25 : d.nY1 - d.nY0)
    .attr('fill', (d:any)=> {return this.handleFillColor(d)})
    .attr('rx', 3)
    .attr('ry', 3)
    .attr('data-test-id', (d:any) => `node-${d.name}`)
    .on("click", (d:any)=>{this.handleOnClickNode(svg, nodes, availableColumns, columnWidth, d)})
    .append('title')
    .text((d: any) => this.handleHoverText(d, className));
  
    
  }

  drawVerticalLink = (start: any, end: any, className: string) => {
    if(this.state.svg === null || !start) return;

    const group = this.state.svg.append('g').attr('class', className);
    const padding =20;
    const startX = this.state.columnWidth * start.column + 35+20;
    const startY = start.nY1;
    const controlPointX1 = startX + (this.state.columnWidth * end.column + padding + 35 - startX);
    const controlPointX2 = this.state.columnWidth * end.column + padding + (this.state.columnWidth * start.column +padding - this.state.columnWidth * end.column + padding);
    const pathdata = `M ${startX}, ${startY} 
    C ${controlPointX1},${startY+(end.nY0-startY)} ${controlPointX1}, ${end.nY0 - 20} ${this.state.columnWidth * end.column + padding + 35},${end.nY0} 
    L ${this.state.columnWidth * end.column + padding}, ${end.nY0} 
    C ${controlPointX2},${startY+(start.nY1-startY)} ${controlPointX2}, ${start.nY1 + 20} ${this.state.columnWidth * start.column +padding},${start.nY1} 
    L ${startX}, ${startY} Z`
    group.append('path').attr('d', pathdata).attr('fill', '#8833FF').attr('opacity', '15%');
  }
  drawLinks = (nodes: any, className: string) => {
    if(this.state.svg === null) return;

    this.state.svg.selectAll(`.${className}`).remove();
    if(nodes.length < 1) return;
    const group = this.state.svg.append('g').attr('class', className);

    for(let i = 1; i < nodes.length; i++){
      const startX = this.state.columnWidth * nodes[i-1].column + 35 + 20;
      const startY = nodes[i-1].nY0;
      const point2Y = nodes[i].nY0;
      const point3X = this.state.columnWidth * nodes[i].column + 20;
      const point3Y = nodes[i].nY0 + nodes[i].nY1 - nodes[i].nY0;
      const point4Y = startY + nodes[i-1].nY1 - nodes[i-1].nY0;
      const controlPointX1 = startX + (point3X -startX)/2;
      const pathdata = `M ${startX}, ${startY} V ${point4Y} C ${controlPointX1}, ${point4Y} ${controlPointX1}, ${point3Y} ${point3X}, ${point3Y} V ${point2Y}  Z `
      group.append('path').attr('d', pathdata).attr('fill', '#8833FF').attr('opacity', '15%');
    } 
  }
  calculateNodeHeight = (level: string, node: any, largestNode: any) => {
    let height
    const minHeight = 20;
    const maxHeight = 180;
    switch(level){
      case 'stage':
        if(largestNode.length <= 0) return 0;
        height = largestNode.stage === node.stage ? maxHeight : (node.count / largestNode.count) * maxHeight;
        console.log("HEIGHT IS " + height);
        return height + minHeight;
      case 'box':
        if(largestNode.length <= 0) return 0;
        height = largestNode.box === node.box ? maxHeight : (node.count / largestNode.count) * maxHeight;
        console.log("HEIGHT IS " + height);
        return height +minHeight;
      case 'flow':
        if(largestNode.length <= 0) return 0;
        height = largestNode.flow === node.flow ?  maxHeight : (node.count / largestNode.count) * maxHeight;
        console.log("HEIGHT IS " + height);
        return height+minHeight;
      default:
        return 0;
    }
  }
  
  
  handleHoverText = (d: any, className: string)=>{
    switch(className){
      case "stages":
        return `${d.stage}`;
      case "stageboxes":
        return `${d.stage}${d.box}`;
      case "stageboxflows":
        return `${d.stage}${d.box}.${d.flow}`;
    }
    
  }
  handleOnClickNode = (svg: any, nodes: any, availableColumns: number, columnWidth: number, d: any) => {
    if(d.type === 'buildcard') return;
    switch(d.className){
      case "stages":
        if(d.stage == this.state.chartStage) {
          this.setState({chartStage: '', chartStageBox: ''});
          this.drawNodes(svg, nodes, availableColumns,columnWidth, d.className);
          svg.selectAll(`.stageboxes`).remove();
          svg.selectAll(`.stageboxeslinks`).remove();
          svg.selectAll(`.stageboxpercentagetext`).remove();
          svg.selectAll(`.stageboxeventnametext`).remove();
          svg.selectAll(`.stageboxflowpercentagetext`).remove();
          svg.selectAll(`.stageboxfloweventnametext`).remove();
          svg.selectAll(`.stageboxflows`).remove();
          svg.selectAll(`.stageboxflowslinks`).remove();
          return;
        }
        this.setState({chartStage: d.stage, chartStageBox: ''});
        this.drawNodes(svg, nodes, availableColumns,columnWidth, d.className);
        //fetch stage 2
        // const boxes = this.fetchStage2()
        this.getUniqueStageBoxes(this.state.token, d.stage)
        svg.selectAll(`.stageboxflows`).remove();
        svg.selectAll(`.stageboxflowslinks`).remove();
        svg.selectAll(`.stageboxflowpercentagetext`).remove();
        svg.selectAll(`.stageboxfloweventnametext`).remove();
        break;
      case "stageboxes":
        if(d.box == this.state.chartStageBox) {
          this.setState({chartStageBox: ''});
          this.drawNodes(svg, nodes, availableColumns,columnWidth, d.className);
          svg.selectAll(`.stageboxflows`).remove();
          svg.selectAll(`.stageboxflowslinks`).remove();
          svg.selectAll(`.stageboxflowpercentagetext`).remove();
          svg.selectAll(`.stageboxfloweventnametext`).remove();
          return;
        }
        this.setState({chartStageBox: d.box});
        this.drawNodes(svg, nodes, availableColumns,columnWidth, d.className);
        this.getUniqueStageBoxFlows(this.state.token, d.stage, d.box)
      break;
    }
  }
  
  handleFillColor = (d:any) => {
    let color = '#bdc8d4';
    switch(d.className){
      case "stages":
        if(d.stage == this.state.chartStage) color = '#FF8956';
      break;
      case "stageboxes":
        if(d.box == this.state.chartStageBox) color= '#FF8956';
      break;
    }
  
    return color;
  }
  
  fetchStage2 = ()=>{
    const largestNode = this.findBoxLargestColumn()
    const boxes = this.state.boxes.map((d:any, index:any) => ({
      ...d,
      nX0: index * (35 + 10),
      nX1: index * (35 + 10) + 35,
      nY0: 350,
      nY1: 350 + this.calculateNodeHeight("box", d, largestNode),
      column: index,
      className: "stageboxes",
      name: d.stage+"."+d.box,
      dewey_code: d.stage+'.'+d.box,
  }));
  
    return boxes;
  }
  adjustWidth = (nodes: any) =>{
    const availableColumns = nodes.length;
    this.setState({ availableColumns: availableColumns})

    if (availableColumns > 8) {
        this.setState({ sChartWidth: this.state.sChartWidth + this.state.columnWidth * (availableColumns - 8) });
    }
  }
  fetchStage3 = ()=>{
    const largestNode = this.findFlowLargestColumn()
    const flows = this.state.flows.map((d:any, index:any) => ({
      ...d,
      nX0: index * (35 + 10),
      nX1: index * (35 + 10) + 35,
      nY0: 610,
      nY1: 610 + this.calculateNodeHeight("flow", d, largestNode),
      column: index,
      className: "stageboxflows",
      name: d.stage+'.'+d.box+d.flow,
      dewey_code: d.stage+'.'+d.box+'.'+d.flow,
  }));
  
  return flows;
  }
  findStageLargestColumn = () => {
    if(this.state.stages.length > 0){
      let maxCount = -1;
      let stageWithMaxCount = {};
  
      this.state.stages.forEach((item:any) => {
        if(item.count > maxCount){
          maxCount = item.count;
          stageWithMaxCount = item;
        }
      })
      return stageWithMaxCount;
    } else return {}
  }
  findBoxLargestColumn = () => {
    if(this.state.boxes.length > 0){
      let maxCount = -1;
      let boxWithMaxCount = {};
  
      this.state.boxes.forEach((item:any) => {
        if(item.count > maxCount){
          maxCount = item.count;
          boxWithMaxCount = item;
        }
      })
      return boxWithMaxCount;
    } else return {}
  }
  
  findFlowLargestColumn = () => {
    if(this.state.flows.length > 0){
      let maxCount = -1;
      let flowWithMaxCount = {};
  
      this.state.flows.forEach((item:any) => {
        if(item.count > maxCount){
          maxCount = item.count;
          flowWithMaxCount = item;
        }
      })
      return flowWithMaxCount;
    } else return {}
  }

drawChart = (ac4:any) => {
  
  const svgElement = this.chartRef2.current as SVGSVGElement;
  if (!svgElement) {
    return;
  }

  const svg = d3.select(svgElement);
  const width2 = +svg.attr('width');
  const height2 = +svg.attr('height');
  const customSort = (a:any, b: any): number => a.index - b.index;

  // .nodeWidth(25)
  const { nodes, links } = d3Sankey()
    .nodePadding(40)
    .nodeSort(customSort)
    .linkSort(customSort)
    .extent([[8, 8], [width2 - 3, height2 - 4]])(ac4);

  svg.selectAll("*").remove();

  svg.append('g')
    .selectAll('rect')
    .style('z-index', 2)
    .data(nodes)
    .join('rect')
    .attr('x', (d: any) => d.x0-10)
    .attr('y', (d: any) => d.y0-5)
    .attr('width', (d: any) => {
      return 10 + d.x1 - d.x0      
    })
    .attr('height', (d: any) => {
      console.log(d,'dddheight')
        return  10 + d.y1 - d.y0 
    })
    .attr('fill', (d: any) => d.color)
    .attr('rx', 3)
    .attr('ry', 3)
    .append('title')
    .text((d: any) => `${d.name}\n${d.value}`);

  let link = svg.append('g')
    .attr('fill', 'none')
    .selectAll('g')
    .data(links)
    .join('g')
    .style('mix-blend-mode', 'multiply');

  link.append('path')
    .attr('d', sankeyLinkHorizontal())
    .attr('stroke-width', (d:any) => Math.max(1, d.width + 10))
    .attr('d', (d: any) => {
        const sourceX = d.source.x1;
        const targetX = d.target.x0-10;
        const sourceY = d.source.y1;
        const targetY = d.target.y0;
        const { cp1, cp2 } = this.controlPoints(sourceX, sourceY, targetX, targetY);

        return `M${sourceX},${sourceY} 
             C${cp1.x},${cp1.y} ${cp2.x},${cp2.y} ${targetX},${targetY} 
             L${targetX},${targetY} 
             C${cp2.x},
              ${cp2.y} 
              ${cp1.x},
              ${cp1.y} 
              ${sourceX},${sourceY} 
             Z`;          

  })
    .style('stroke', '#EFE7FF')
    .style('fill', "none");

    const nodeLabels = svg.append('g')
    .selectAll('text')
    .data(nodes)
    .join('text')
    .attr('class', 'node-label')
    .attr('x', (d: any) => d.x1 + 10) 
    .attr('y', (d: any) => (d.y1 + d.y0) / 2) 
    .text((d: any, i: number) => d.name)
    .style('visibility', 'hidden')

    svg.selectAll('rect')
    .on('mouseenter',  (event, d) => {
      nodeLabels.filter((nodeData) => nodeData === d).style('visibility', 'visible'); 
    })
    .on('mouseleave', (event, d) => {
      nodeLabels.filter((nodeData) => nodeData === d).style('visibility', 'hidden'); 
    });
   

}
  onApplyStage = (selected: string[]) => {
    this.setState({selectedStage: selected})
  }

  onDeleteStage = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation()
    this.setState(prev => ({...prev, selectedStage: []}))
  }

  deleteCurrentStage = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, current: string) => {
    event.stopPropagation()
    this.setState(prev => ({...prev, selectedStage: prev.selectedStage.filter(val => val !== current)}))
  }

  onApplySubStage = (selected: string[]) => {
    this.setState(prev => ({...prev, selectedSubStage: selected}))
  }

  onApplyFlow = (selected: string[]) => {
    this.setState(prev => ({...prev, selectedFlowData: selected}))
  }

  onApplySurface = (selected: string[]) => {
    this.setState(prev => ({...prev, selectedSurfaceData: selected}))
  }
  

  onDeleteFlow = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation()
    this.setState(prev => ({...prev, selectedFlowData: [], selectedSubFlowData: []}))
  }
  onDeleteSurface = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation()
    this.setState(prev => ({...prev, selectedSurfaceData: [], selectedSurfaceVariantsData: []}))
  }

  onApplySubFlow = (selected: string[]) => {
    this.setState(prev => ({...prev, selectedSubFlowData: selected}))
  }

  deleteCurrentFlow = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, current: string) => {
    event.stopPropagation()
    this.setState(prev => ({...prev, selectedFlowData: prev.selectedFlowData.filter(val => val !== current)}))
  }
  deleteCurrentSurface = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, current: string) => {
    event.stopPropagation()
    this.setState(prev => ({...prev, selectedSurfaceData: prev.selectedSurfaceData.filter(val => val !== current)}))
  }

  onApplySurfaceVariants = (selected: string[]) => {
    this.setState(prev => ({...prev, selectedSurfaceVariantsData: selected}))
  }

  onDeleteSurfaceVariants = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation()
    this.setState(prev => ({...prev, selectedSurfaceVariantsData: []}))
  }

  deleteCurrentSurfaceVariants = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, current: string) => {
    event.stopPropagation()
    this.setState(prev => ({...prev, selectedSurfaceVariantsData: prev.selectedSurfaceVariantsData.filter(val => val !== current)}))
  }

  onApplyCampaign = (selected: string[]) => {
    this.setState(prev => ({...prev, selectedCampaignData: selected}))
  }

  onDeleteCampaign = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation()
    this.setState(prev => ({...prev, selectedCampaignData: []}))
  }

  deleteCurrentCampaign = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, current: string) => {
    event.stopPropagation()
    this.setState(prev => ({...prev, selectedCampaignData: prev.selectedCampaignData.filter(val => val !== current)}))
  }

  handleDatePicker = (e: unknown) => {
    this.setState(prev => ({showDatePicker: !prev.showDatePicker}))

    let event = e as React.MouseEvent<HTMLDivElement, MouseEvent>

    this.setState(
      {
        calendarAnchorEl: event?.currentTarget,
      },
      () => {
        this.setState({
          calendarPopover: Boolean(this.state.calendarAnchorEl),
        });
      }
    );
  }

  getFormattedDate = (): string => {
    if(this.state.selectedDates?.length === 2) {
      let value1 = moment(this.state.selectedDates[0]).format("MMM DD")
      let value2 = moment(this.state.selectedDates[1]).format("MMM DD")
      return value1 + " - " + value2
    }
  
    return ""
  }

  handleDateChange = (dates: DateVal[] | Range<DateVal> | DateVal) => {
    if(Array.isArray(this.state.selectedDates))
    this.setState({selectedDates:dates as DateVal[]});
  }

  handleNavigateWhiteboard = () => {
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(getName(MessageEnum.NavigationTargetMessage), 'Whiteboard');
    to.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(to);
  }
  // Customizable Area End
}
