import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { dashboard_Icon, explorer_Icon, layout_Corners_Dashboard, search_Icon } from "./assets";
import React from "react";
// Customizable Area Start
import MergeEngineUtilities from "../../utilities/src/MergeEngineUtilities";
import {debounce} from "@mui/material";

export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
}

export interface DashboardCard {
  id: number,
  name: string,
  description: string,
  popularity: string,
  url: string,
  url_type: string,
  card_section_id: number,
  allow_read_only: boolean | null,
  created_at?: string,
  updated_at?: string,
}
export interface TrialData {
  id: string;
  type: string;
  attributes: {
    name: string;
    created_at: string;
    updated_at: string;
    url: string;
    icon_url: string;
    id: number
  }
}

export interface DashboardCardData {
  id?: string;
  type: string;
  attributes: {
    name: string,
    created_at: string,
    updated_at: string,
    dashboard_cards: Array<DashboardCard>
    dashboard_cards_size: {
      dashboard_cards_count: number,
      dashboard_cards_count_without_filter: number,
    }
  }
}

export interface ApiDataType {
  data: [

    {
      id: string;
      type: string;
      attributes: {
        name: string;
        created_at: string;
        updated_at: string;
        card_section_id: number,
        id: number;

        description: string;
        popularity: string;
        url: string;
        url_type: string;


        dashboard_cards: [
          {
            id: number;
            name: string;
            description: string;
            popularity: string;
            url: string;
            url_type: string;
            card_section_id: number;
          },
        ]
      }
    },
  ],
  meta: {
    message: string;
  }
}
export interface TrialsApiDataType {
  data: [
    {
      id: string;
      type: string;
      attributes: {
        name: string;
        created_at: string;
        updated_at: string;
        url: string;
        icon_url: string;
        id: number
      }
    },
  ],
  meta: {
    message: string;
  }
};

export interface SectionApiDataType {
  id: string,
  type: string,
  attributes: {
    description?: string;
    name: string;
    created_at?: string;
    updated_at?: string;
    url: string;
    icon_url: string;
    id: number;
    card_section_id: number;
    popularity: string;
    url_type: string
    allow_read_only: boolean | null;
  },
  meta: {
    message: string;
  }
}



export interface ErrorResponse {
  errors: string;
}
export interface DashboardCardsData {
  data: Array<DashboardCardData>
}

interface DashboardSidebarTrials {
  id: string,
  type: string,
  attributes: {
    id: number,
    name: string,
    url: string,
    created_at: string,
    updated_at: string,
    icon_url: string
  }
}

interface LoadMoreCardInterface {
  id: string
  type: string
  attributes: {
    name: string
    description: string
    popularity: string
    url: string
    url_type: string
    card_section_id: number
    created_at: string
    updated_at: string
  }
}

export const sideBarData: any = {
  data: []
}

type Data<T> = {
  data: T
}

type Favourite = {
  id: string
  type: string
  attributes: {
    favouriteable_id: number
    favouriteable_type: string
    user_id: number
    favourite: boolean
    created_at: string
    updated_at: string
  }
}

// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: {
    type: string;
    quantity: string;
  }[];
  totalCandidates: string;
  type: string;
  token: string;
  errorMsg: string;
  loading: boolean;
  setButtonVisable: boolean;
  DashboardCardsData: Array<DashboardCardData>;
  DashboardCardsInit: Array<DashboardCardData>;
  userToken: string;
  sectionID: string;
  isLoadMore: boolean;
  isLoadLess: boolean;
  descriptionArr: any;
  searchValue: string;
  showDropdown: boolean,
  showSection: boolean,
  selectedOption: string[],
  selectedSection: string[],
  mirrorSelectedOption: string[],
  mirrorSelectedSection: string[],
  isGetAll: boolean,
  isFavourite: boolean,
  dashboardSidebarTrialsData: Array<DashboardSidebarTrials>;
  favourites: Map<string, string>;
  accordionExpanded: string[];
  isCallDashboardDataAPIProcessed: boolean,
  // Customizable Area End
}
interface SS { }

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  getDashboardCardDataApiCallId: string = "";
  getDashboardSidebarTrialsAPIId: string = "";
  getDashboardLoadMoreCardAPIId: string = "";
  getSectionInitialCardsApiId = '';
  postFavouriteApiId = '';
  getFavouriteApiId = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CallDashboardData)
    ];
    this.state = {
      type: "",
      dashboardData: [],
      totalCandidates: "",
      errorMsg: "",
      token: "",
      loading: false,
      searchValue: '',
      setButtonVisable: false,
      DashboardCardsData: [],
      DashboardCardsInit: [],
      userToken: '',
      sectionID: '1',
      isLoadMore: false,
      isLoadLess: false,
      descriptionArr: [],
      showDropdown: false,
      showSection: false,
      selectedOption: [],
      selectedSection: [],
      mirrorSelectedOption: [],
      mirrorSelectedSection: [],
      isGetAll: false,
      isFavourite: false,
      dashboardSidebarTrialsData: [],
      favourites: new Map(),
      accordionExpanded: [],
      isCallDashboardDataAPIProcessed: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    console.log("mounted");
    const dashboardMsg: Message = new Message(
      getName(MessageEnum.AnalyticsScreenMessage)
    );
    dashboardMsg.addData(
      getName(MessageEnum.AnalyticsScreenMessageName),
      'dashboard'
    );
    // if(!this.state.userToken) 
    
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let token: any = params.get('t');
    
    const localStorageUserToken = localStorage.getItem("userInfo")
    const parseLocalStorage = JSON.parse(localStorageUserToken ?? "{}") || {}

    if(token !== null && token != parseLocalStorage.session_token) {
      parseLocalStorage.session_token = token;
      localStorage.setItem("userInfo", JSON.stringify(parseLocalStorage))
  }else{
      this.getFavourites(parseLocalStorage.session_token)
      this.initialCall(parseLocalStorage.session_token)
    }
    
    setTimeout(() => {
      this.getScrollData()

    }, 1500)
    this.send(dashboardMsg);
    this.sendAnalyticsTrack('dashboard.loaded', "892")
    // Customizable Area End
  }

  // Customizable Area Start

  initialCall(token: string) {

    this.setState({ userToken: token })
    this.getDashboardData(token,this.state.selectedOption,'');
  }
  getPopularityParam = (option: string[]) => {
    let popularity = ''
    if(option.length >0 && option.length <3){
      popularity = '[';
      
      for(let i = 0; i < option.length; i++){
        console.log("popularity : " + option[i])
        popularity = popularity+'"'+option[i]+'"'+ (i !== option.length-1 ? ',' : ']');
        

      }
    }
    return popularity;
  }
  getSectionsParam = ()=>{
    let sections = ''
    if(this.state.selectedSection.length >0 && this.state.selectedSection.length <this.state.DashboardCardsInit.length){
      sections = '[';
      const selectedsections = this.state.selectedSection;
      for(let i = 0; i < selectedsections.length; i++){
        sections = sections+'"'+selectedsections[i]+'"'+ (i !== selectedsections.length - 1 ? ',' : ']');
        
      }
    }
    return sections;
  }
  getDashboardData(token: string,option:string[],searchValue:string): boolean {
    if ((option.length === 0|| option.length === this.state.DashboardCardsInit.length) && searchValue === '' && this.state.selectedSection.length === 0) {
      this.setState(prev => ({ ...prev, isGetAll: true }))
    } else {
      this.setState(prev => ({ ...prev, isGetAll: false }))
    }
    const webHeader = {
      'Content-Type': 'application/json',
      'token': `${token}`,
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDashboardCardDataApiCallId = webRequestMessage.messageId;
    const popularity = this.getPopularityParam(option);

    const sections = this.getSectionsParam();
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.dashboardConMngntCardSectionsAPIEndPoint + '?popularity=' + popularity + '&name=' + searchValue + '&section_id=' + sections
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);

    return true;
  }

  async receive(from: string, message: Message) {

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let webErrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (this.inValidResponse(webResponseJson)) {
        this.responseFailureCallBack(webApiRequestCallId, webResponseJson);
      }
      if (this.isValidResponse(webResponseJson) || webApiRequestCallId === this.postFavouriteApiId) {
        this.responseSucessCallBack(webApiRequestCallId, webResponseJson);
      }
    }else if (message.id === getName(MessageEnum.CallDashboardData)) {
      !this.state.isCallDashboardDataAPIProcessed ? this.callDashboardDataCallback(message) : this.setState({isCallDashboardDataAPIProcessed: false});
    }
  }


  callDashboardDataCallback(message: Message){
    const userInfo = message.getData(getName(MessageEnum.CallDashboardDataMessage));
    this.setState({isCallDashboardDataAPIProcessed: true});
    this.setState({ userToken: userInfo.session_token });
    this.initialCall(userInfo.session_token);
    this.getFavourites(userInfo.session_token);
  }

  isValidResponse = (responseJson: ApiDataType) => {
    return responseJson && responseJson.data;
  };

  inValidResponse = (responseJson: ErrorResponse) => {
    return responseJson && responseJson.errors;
  };

  responseFailureCallBack = async (apiRequestCallId: string, responseJson: string) => {
    if (apiRequestCallId === this.getDashboardCardDataApiCallId) {
      this.parseApiCatchErrorResponse(responseJson);
    }
  };

  getFavourites = (token: string) => {
    const getFav = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getFavouriteApiId = getFav.messageId
    getFav.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.favouriteEndpoint + '?favouriteable_type=BxBlockUser::User',
    )
    getFav.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.getApiMethod,
    )
    const header = {
      'Content-Type': 'application/json',
      'token': token,
    }
    getFav.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    )
    this.send(getFav)
  }

  responseSucessCallBack = async (apiRequestCallId: string, responseJson: ApiDataType & TrialsApiDataType & Array<SectionApiDataType> & Data<Favourite> & Data<Favourite[]>) => {
    if (apiRequestCallId === this.getDashboardCardDataApiCallId) {
      this.setState(previous => {
        const newData = { ...previous, DashboardCardsData: responseJson.data as never };
        if (previous.isGetAll) {
          newData.DashboardCardsInit = [ ...responseJson.data ].map(item => ({
            ...item,
            attributes: {
              ...item.attributes,
              dashboard_cards: [...item.attributes.dashboard_cards],
            }
          })) as never
          newData.isGetAll = false;
        }
        return newData
      })
    };
    if (apiRequestCallId === this.getDashboardSidebarTrialsAPIId) {
      this.setState({ dashboardSidebarTrialsData: responseJson.data })
      sideBarData.data = responseJson.data
    };
    this.handleDashboardLoadMore(apiRequestCallId, responseJson as never)
    this.handleGetFavourite(apiRequestCallId, responseJson)
    this.handlePostFavourite(apiRequestCallId, responseJson as never)
    this.handleInitialSectionCards(apiRequestCallId, responseJson as never)
  };

  handleDashboardLoadMore = (apiId: string, responseJson: Data<LoadMoreCardInterface[]>) => {
    if (apiId !== this.getDashboardLoadMoreCardAPIId) {
      return
    }
    const temp: DashboardCard[] = []
    for (const item of responseJson.data) {
      const { id, attributes } = item;
      const mapTemp = { id, ...attributes };
      temp.push(mapTemp as never)
    }
    let updatedSectionsData: DashboardCardData[] = [...this.state.DashboardCardsData]
    let toUpdate: DashboardCardData | undefined
    if (this.state.isLoadMore) {
      toUpdate = this.state.DashboardCardsInit.find(item => item.id === this.state.sectionID)
      updatedSectionsData = updatedSectionsData.map(item => (
        item.id !== this.state.sectionID ? item : (toUpdate ?? item)
      ))
    } else {
      toUpdate = updatedSectionsData.find(item => item.id === this.state.sectionID)
    }
    if (toUpdate) {
      toUpdate.attributes.dashboard_cards.push(...temp)
    }
    this.setState({
      DashboardCardsData: updatedSectionsData, isLoadMore: false,
    })
    this.sendAnalyticsTrack("load_more.loaded", "892")
  }

  handleInitialSectionCards = (apiId: string, result: Data<DashboardCardData>) => {
    if (apiId !== this.getSectionInitialCardsApiId) {
      return;
    }
    
    this.setState(prev => ({
      ...prev,
      isLoadLess: false,
      DashboardCardsData: prev.DashboardCardsData.map(item => {
        if (this.state.isLoadMore || item.id !== result.data.id) {
          return item;
        }
        return {
          ...item,
          attributes: {
            ...item.attributes,
            dashboard_cards: result.data.attributes.dashboard_cards,
            dashboard_cards_size: result.data.attributes.dashboard_cards_size
          }
        };
      }),
      DashboardCardsInit: prev.DashboardCardsInit.map(item => {
        if (!this.state.isLoadMore || item.id !== result.data.id) {
          return item;
        }
        return {
          ...item,
          attributes: {
            ...item.attributes,
            dashboard_cards: result.data.attributes.dashboard_cards,
            dashboard_cards_size: result.data.attributes.dashboard_cards_size
          }
        }
      })
      }), () => {
        if (this.state.isLoadMore) {
          this.getDashboardLoadMoreCard(this.state.userToken, String(result.data.id), this.state.selectedOption, this.state.searchValue)
        }
      })


  }


  handleGetFavourite = (apiId: string, responseJson: Data<Favourite[]>) => {
    if (apiId !== this.getFavouriteApiId) {
      return
    }
    this.setState(prev => {
      let { favourites, ...rest } = prev
      favourites.clear()
      favourites = responseJson.data.reduce((reduced, item) => {
        const favId = item.attributes.favouriteable_id.toString()
        return reduced
          .set(favId, item.id)
          .set("id" + item.id, favId)
      }, favourites)
      return { ...rest, favourites }
    })
  }

  handlePostFavourite = (apiId: string, responseJson: Data<Favourite> & Record<string, string>) => {
    if (apiId !== this.postFavouriteApiId) {
      return
    }
    const after = () => {
      this.sendDashbaordFavorites(this.state.userToken)
      this.getSectionInitialCards(this.state.sectionID)
    }
    if (responseJson?.data && responseJson.data.id) {
      const sectionData = this.state.DashboardCardsData.find(item => item.id === this.state.sectionID)?.attributes.dashboard_cards.length; 
      
      if (sectionData && sectionData > 8) {
        this.setState({ isLoadMore: true });
      }
      this.setState(prev => {
        const { favourites, ...rest } = prev
        const favId = responseJson.data.attributes.favouriteable_id.toString()
        const recordId = responseJson.data.id
        return {
          ...rest,
          favourites: favourites
            .set(favId, recordId)
            .set("id" + recordId, favId)
        }
      }, after)
    } else if (responseJson?.message) {
      const matches = responseJson.message.match(/id (\d+)/)
      if (matches && matches.length > 1) {
        const sectionData = this.state.DashboardCardsData.find(item => item.id === this.state.sectionID)?.attributes.dashboard_cards.length; 
      
      if (sectionData && sectionData > 8) {
        this.setState({ isLoadMore: true });
      }
        this.setState(prev => {
          let { favourites, ...rest } = prev
          const recordId = "id" + matches[1]
          const favId = favourites.get(recordId)
          if (favId) {
            favourites.delete(recordId)
            favourites.delete(favId)
          }
          return {
            ...rest,
            favourites,
          }
        }, after)
      }
    }
  }

  showIframe = (data: { name: string, url: string }) => {
    localStorage.setItem('trialData', data.url);
    localStorage.setItem('trialName', data.name);
    MergeEngineUtilities.navigateToScreen('DashboardCard', this.props)
  };

  showIframeId = (id: string) => {
    this.props.navigation.navigate("DashboardCardId", { id })
  }

  getDashboardLoadMoreCard(token: string, sectionType: string, option: string[], searchValue: string): boolean {
    this.setState({ sectionID: sectionType })
    const webHeader = {
      'Content-Type': 'application/json',
      'token': `${token}`,
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDashboardLoadMoreCardAPIId = webRequestMessage.messageId;
    const popularity = this.getPopularityParam(option)
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.dashboardConMngntCardSectionsAPIEndPoint + "/" + sectionType + "/load_more_cards?popularity="+ popularity+ '&name=' + searchValue
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }

  handleExpand = (item: any) => {
    if (!this.state.descriptionArr.includes(item.id)) {
      const updatedItems = [...this.state.descriptionArr, item.id];
      this.setState({ descriptionArr: updatedItems })
    }

  };
  handleExpandLess = (item: any) => {
    const filteredArray = this.state.descriptionArr.filter((val: any) => val !== item.id);
    this.setState({ descriptionArr: filteredArray })

  };

  handleLoadLess = (value: any) => {
    this.setState({isLoadLess: true});
    const dashboardCardLessData = this.state.DashboardCardsData.map((item: any) => {
      if (item.attributes.name.includes(value.attributes.name)) {
        return {
          ...item,
          attributes: {
            ...item.attributes,
            dashboard_cards: item.attributes.dashboard_cards.slice(0, 8),
          },
        };
      }
      return item;
    })
    this.setState(prev => ({
      ...prev,
      isLoadMore: false,
      DashboardCardsData: dashboardCardLessData,
    }))
  }
  
    

  getScroll = () => {
    localStorage.setItem('scrollValue', JSON.stringify(window.scrollY))
  }

  getScrollData = () => {
    const getIframe = localStorage.getItem('IframeClicked')
    if (getIframe === 'true') {
      const scrollValue = localStorage.getItem('scrollValue')
      if (scrollValue) {
        const storedValue = JSON.parse(scrollValue)
        window.scrollTo({
          top: storedValue,
          behavior: "smooth"
        });
      }
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  }

  handleSearchChangeApi = debounce((value: string) => {
    this.getDashboardData(this.state.userToken, this.state.selectedOption, value);
    this.sendAnalyticsTrack('dashbaord.search.value', "194")
  }, 500)

  handleSearchChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const value = event.target.value
    this.setState({ searchValue: value });
    this.handleSearchChangeApi(value)
  }

  handleSearhClick = () => {
    this.sendAnalyticsTrack('dashboard.search', "194")
  }

  handleCancelSearch = () => {
    this.setState({ searchValue: '' })
    this.getDashboardData(this.state.userToken, this.state.selectedOption, '');
  }
  handleFav = (value: DashboardCard, sectionId: string) => {
    this.setState({ sectionID: sectionId })
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.postFavouriteApiId = message.messageId
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.favouriteEndpoint,
    )
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.postApiMethod,
    )
    const header = {
      'Content-Type': 'application/json',
      'token': `${this.state.userToken}`,
    }
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    )
    const body = {
      'data': {
        'favouriteable_id': value.id,
        favouriteable_type: "BxBlockContentManagement::DashboardCard",
        favourite: true
      }
    }
    message.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body),
    )
    this.send(message)
    this.sendAnalyticsTrack(`dashboard.${this.state.favourites.has(value.id as unknown as string) ? "un" : ""
      }favourite.${value.id}`, "1143")
  }
  handleDropdownToggle = (event: React.MouseEvent<HTMLDivElement | Document, MouseEvent>) => {
    if (!this.state.showDropdown) {
      event.stopPropagation()
    }
    
    this.setState((prevState) => ({
      showDropdown: !prevState.showDropdown,
      showSection: false,
      mirrorSelectedOption: this.state.selectedOption
    }));
    this.sendAnalyticsTrack('dashboard.popularity', "1178");
  };
  handleLoadMore = (item: string) => {
    this.getDashboardLoadMoreCard(this.state.userToken, String(item), this.state.selectedOption, this.state.searchValue)
  }
  getSectionInitialCards = (id: string) => {
    const webHeader = {
      'Content-Type': 'application/json',
      'token': `${this.state.userToken}`,
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSectionInitialCardsApiId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.dashboardConMngntCardSectionsAPIEndPoint+
      '/'+
      id+
      '/initial_cards?popularity='+
      this.getPopularityParam(this.state.selectedOption)+
      '&name='+
      this.state.searchValue
    );
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    this.send(webRequestMessage)
  }
  handleApplyPopularity = ()=>{
    this.getDashboardData(this.state.userToken, this.state.selectedOption, this.state.searchValue);
    this.setState(prev => ({ ...prev, showDropdown: false }))
  }
  handleOptionSelect = (option: string) => {
    console.log('OPTION: '+option)
    const {selectedOption} = this.state;
    if(option === ''){
      if(this.state.selectedOption.length === 3){
        this.setState({selectedOption: []})
      }else this.setState({selectedOption: ['High', 'Low', 'Medium']})
      return;
    }

    if(selectedOption.includes(option)){
      const index = selectedOption.indexOf(option);
      selectedOption.splice(index, 1);
      this.setState({
        selectedOption: selectedOption
      },()=>{console.log(this.state.selectedOption)})
    }else{
     this.setState({
      selectedOption: [...selectedOption, option]
     }) 
    }
    console.log(this.state.showDropdown)
    

    this.sendAnalyticsTrack(`dashbaord.popularity.${this.convertToSnakeCase(option)}`, "1178")
  };

  handleClosePopularity = (event: any) => {
    if(event && event.target.closest('.menu-tooltip-content') || !this.state.showDropdown) return;

    this.setState(prev => ({ ...prev, showDropdown: false, selectedOption: this.state.mirrorSelectedOption }))
    

  }

  handleSectionToggle = (event: React.MouseEvent<HTMLDivElement | Document, MouseEvent>) => {
    if (!this.state.showSection) {
      event.stopPropagation()
    }
    this.setState((prevState) => ({
      showSection: !prevState.showSection,
      showDropdown: false,
      mirrorSelectedSection: this.state.selectedSection
    }));
    this.sendAnalyticsTrack('dashboard.section', "1178")
  };

  handleCloseSection = (event: any) => {
    if(event && event.target.closest('.menu-tooltip-content') || !this.state.showSection) return;
    
    this.setState(prev => ({ ...prev, showSection: false, selectedSection: this.state.mirrorSelectedSection}))
  }
  handleApplySections = ()=>{
    this.getDashboardData(this.state.userToken, this.state.selectedOption, this.state.searchValue);
    this.setState(prev => ({ ...prev, showSection: false }))
  }

  handleSectionSelect = (value: string) => {

    console.log('SELECT: '+value)
    const {selectedSection} = this.state;
    if(value === ''){
      if(this.state.selectedSection.length === this.state.DashboardCardsInit.length){
        this.setState({selectedSection: []})
      }else{
        let all: string[] = this.state.DashboardCardsInit.map((val) =>  val.id as string)
        this.setState({selectedSection: all})
      }
      return;
    }

    if(selectedSection.includes(value)){
      const index = selectedSection.indexOf(value);
      selectedSection.splice(index, 1);
      this.setState({
        selectedSection: selectedSection
      },()=>{console.log(this.state.selectedSection)})
    }else{
     this.setState({
      selectedSection: [...selectedSection, value]
     },()=>{console.log(this.state.selectedSection)}) 
    }

    
    this.sendAnalyticsTrack(`dashboard.section.${this.convertToSnakeCase(value)}`, "1178")
  }

  sendAnalyticsTrack = (trackName: string, featureID: string) => {
    if(!this.state.userToken) return;
    const message: Message = new Message(
      getName(MessageEnum.AnalyticsTrackMessage)
    );
    message.addData(getName(MessageEnum.AnalyticsTrackMessageName), trackName);
    message.addData(getName(MessageEnum.AnalyticsTrackMessageProperties), {
      "BC_ID": "404910",
      "FEATURE_ID": featureID,
    })
    this.send(message);

  }

  convertToSnakeCase(text: string) {
    let snakeCase = text.replace(/[\s]+/g, '_')
    snakeCase = snakeCase.replace(/([a-z])([A-Z])/g, '$1_$2')
    return snakeCase.toLowerCase()
  }

  toggleExpandAll = () => {
    this.setState(prev => {
      let { accordionExpanded, ...rest } = prev
      if (accordionExpanded.length === 0) {
        accordionExpanded = prev.DashboardCardsData.map(item => item.id as string)
      } else {
        accordionExpanded = []
      }
      return { ...rest, accordionExpanded }
    })
  }

  toggleExpand = (sectionId: string) => {
    return (_event: React.ChangeEvent<{}>, expanded: boolean) => {
      this.setState(prev => {
        let { accordionExpanded, ...rest } = prev
        if (expanded) {
          accordionExpanded.push(sectionId)
        } else {
          accordionExpanded = accordionExpanded.filter(item => item !== sectionId)
        }
        return { ...rest, accordionExpanded }
      })
    }
  }

  sendDashbaordFavorites = (token: string) => {
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage))
    message.messageId = 'dashboard-favourite-sidebar'
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.favoriteDashboardCards,
    )
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.getApiMethod,
    )
    const header = {
      'Content-Type': 'application/json',
      'token': token,
    }
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    )
    this.send(message)
  }
  // Customizable Area End
}
