import React from "react";

// Customizable Area Start
// Customizable Area End

import AnalyticsController, {
  Props
} from "./AnalyticsController.web";

//@ts-ignore
import * as d3 from 'd3';

//@ts-ignore
import { ac2data } from "./ac2data"

//@ts-ignore
import { sankey as d3Sankey,  SankeyNode, SankeyLink, SankeyNodeMinimal } from "d3-sankey";


// Customizable Area Start
interface SS { }



interface SankeyNodeExtended extends SankeyNode<SankeyNodeMinimal<{}, {}>, SankeyLink<{}, {}>> {
  column: number;
}

const data = [
  ["From", "To", "Weight"],
  ["Offsite0", "Nurture0", 5],
  ["Offsite0", "Nurture1", 1],
  ["Offsite0", "Nurture2", 1],
  ["Offsite0", "Nurture3", 1],
  ["Offsite1", "Nurture0", 1],
  ["Offsite1", "Nurture1", 5],
  ["Offsite1", "Nurture3", 1],
  ["Offsite2", "Nurture0", 1],
  ["Offsite2", "Nurture1", 1],
  ["Offsite2", "Nurture2", 5],
  ["Offsite2", "Nurture3", 1],
  ["Offsite3", "Nurture0", 1],
  ["Offsite3", "Nurture1", 1],
  ["Offsite3", "Nurture2", 1],
  ["Offsite3", "Nurture3", 5],
  ["Nurture0", "Lead on Studio page 1", 2],
  ["Nurture0", "Lead on Studio page 2", 1],
  ["Nurture0", "Lead on Studio page 4", 1],
  ["Nurture0", "Lead on Studio page 0", 3],
  ["Nurture1", "Lead on Studio page 1", 1],
  ["Nurture1", "Lead on Studio page 2", 3],
  ["Nurture1", "Lead on Studio page 3", 3],
  ["Nurture1", "Lead on Studio page 4", 3],
  ["Nurture1", "Lead on Studio page 0", 1],
  ["Nurture2", "Lead on Studio page 2", 1],
  ["Nurture2", "Lead on Studio page 4", 3],
  ["Nurture2", "Lead on Studio page 0", 1],
  ["Nurture3", "Lead on Studio page 1", 1],
  ["Nurture3", "Lead on Studio page 2", 1],
  ["Nurture3", "Lead on Studio page 4", 2],
  ["Nurture3", "Lead on Studio page 0", 7],
  ["Lead on Studio page 0", "Product comms sent to customers 0", 5],
  ["Lead on Studio page 0", "Product comms sent to customers 1", 1],
  ["Lead on Studio page 0", "Product comms sent to customers 2", 3],
  ["Lead on Studio page 1", "Product comms sent to customers 0", 5],
  ["Lead on Studio page 1", "Product comms sent to customers 1", 1],
  ["Lead on Studio page 1", "Product comms sent to customers 2", 3],
  ["Lead on Studio page 2", "Product comms sent to customers 0", 5],
  ["Lead on Studio page 2", "Product comms sent to customers 1", 1],
  ["Lead on Studio page 2", "Product comms sent to customers 2", 3],
  ["Lead on Studio page 3", "Product comms sent to customers 0", 5],
  ["Lead on Studio page 3", "Product comms sent to customers 1", 1],
  ["Lead on Studio page 3", "Product comms sent to customers 2", 3],
  ["Lead on Studio page 4", "Product comms sent to customers 0", 5],
  ["Lead on Studio page 4", "Product comms sent to customers 1", 1],
  ["Lead on Studio page 4", "Product comms sent to customers 2", 3],
];



// Customizable Area End


export default class AnalyticsChart2 extends AnalyticsController {
  private chartRef: React.RefObject<SVGSVGElement>;
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.chartRef = React.createRef()
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    
  }
  
  sanekyChart = () => (
    <div
      style={{
        overflowX: 'auto',
        margin: '0px 30px',
        padding: 5,
      }}
    >
      
    </div>
  )

  controlPoints2 = (x: any, y: any, x2: any, y2: any) => x < x2
    ? {
      cp1: { x: x + (x2 - x) / 3 * 2, y },
      cp2: { x: x + (x2 - x) / 3, y: y2 }
    }
    : {
      cp1: { x: x - (x - x2) / 3, y: 0 },
      cp2: { x: x2 + (x - x2) / 3, y: 0 }
    };



  




  // Customizable Area End


  render() {
    return (
      // Customizable Area Start
      <div >
        
        <div>

          <div  >
          
            <svg ref={this.chartRef} width={this.state.sChartWidth} height={800}>
              <g />
            </svg>

          </div>
        </div>
      </div >
      // Customizable Area End
    );
  }
}


