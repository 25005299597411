import React from "react";
  // Customizable Area Start
import {
  Typography,
  Button,
  Grid,
  Link as MuiLink,
} from "@mui/material";
import { logo, defaultIcon } from "./assets";

import LandingPageController, { Props } from "./LandingPageController";
// @ts-ignore
import {baseURL} from "../../../framework/src/config.js"
import "./style.css";

// Customizable Area End

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start

  // Customizable Area End

  render(): JSX.Element {
    return (
      // Customizable Area Start
      <div className="landingBody">
        <div className="loginBox" style={{ display:'flex',alignItems:'center' }}>
          <Typography className="headerlogo">
            <img src={logo} alt="Logo" />
          </Typography>
          <MuiLink  href={this.Login(baseURL)}  className="btnLoginLink">
            <Button data-test-id='login' className="btnlg" style={{ textTransform: 'none',borderRadius:'8px', color: '#3C3E49' }} onClick={this.sendAnalyticTrack("ButtonPressed", "1583")}>Login</Button>
          </MuiLink>
        </div>

        <div className="builderText">
          <Typography className="builderheading">
            {this.state.banner?.attributes.title}
          </Typography>
          <Typography className="subtext">
            {this.state.banner?.attributes.subtitle}
          </Typography>
        </div>

        <div className="boxKey">
          <Typography className="keyTitle">Key features</Typography>
        </div>

        <Grid container spacing={3} className="sub">
          {
            this.state.features && this.state.features.map((item: any, index: any) => {
              return (<Grid item xs={12} md={4} lg={4} key={index}>
                <div className="subBox">
                  <p className="subBoxImg">
                    <img
                      src={item.attributes.image_url.url
                        ? baseURL +  item.attributes.image_url.url
                        : defaultIcon}
                      alt={item.attributes.title}
                      className="featureImage"
                      onError={this.defaultImageError}
                    />
                  </p>
                  <Typography className="dashboardHeading">
                    {item.attributes.title}
                  </Typography>
                  <Typography className="dashboardSubtitle">
                    {item.attributes.description}
                  </Typography>
                </div>
              </Grid>
              )
            })
          }

        </Grid>
      </div>
      // Customizable Area End
    );
  }
}
