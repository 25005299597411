//@ts-nocheck
import React, { Fragment } from "react";
// Customizable Area Start
import {
  mic, logoNatasha, arrowIcon
} from "../src/assets";

import ContentManagementController, {
  Props,
} from "./ContentManagementController";

// Customizable Area End


export default class ContentManagement extends ContentManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <div style={webStyle.mainWrapper} className="mainWrapperNatasha">
        {!this.state.chat.length && <div style={webStyle.scndWrapper} >
          <div style={webStyle.textButtonDiv}>
            <div style={webStyle.textButton}>Natasha</div>
          </div>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            bottom: 65
          }} >
            <div style={webStyle.eightyWidth} className="waraperWidth">
              <div style={{display:'flex',alignItems:'center',justifyContent:'center',padding:'40px'}}>
              <img src={logoNatasha} alt="Logo" style={{height:'170px',width:'170px'}}/>
              </div>
              <div style={webStyle.firstInputFormDiv} >
                <form style={webStyle.firstFormStyle}
                  onSubmit={(e) => this.formSubmit(e)}
                  data-test-id="form-submit"
                >
                  <input type="text" style={webStyle.firstInputChat} placeholder="Start typing..."
                    onChange={/* istanbul ignore next */(e) => this.inputChange(e)} data-test-id="chat-input" />
                  <img src={mic} alt="Natasha Icon" style={webStyle.NatashaIcon} />
                </form>
                <div style={webStyle.flexdisplay} className="suggestionsMainBox">
                  <div style={webStyle.suggestionsMainDiv}>
                    <span style={webStyle.suggestionMainSpan}>Suggestions</span>
                    {this.state.suggestions.map((data, index) =>
                      <div key={index} style={webStyle.suggestionContainer}
                        data-test-id={`suggestion-${index}`}
                        onClick={() => this.onSelectMessage(data)}
                      >
                        <div>{data}</div>
                        <div style={webStyle.flexdisplayItemAlign}><img src={arrowIcon} alt="Arrow Icon" style={{height:'11px',width:'5px'}} /></div>
                      </div>)}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>}
      </div>
      // Customizable Area End

    );
  }
}
// Customizable Area Start

const Styles = {
  ImgTagStyle: {
    width: '100px',
    height: '100px',
    padding: '10px'
  },
}

const webStyle = {
  NatashaIcon: {
    display: "inline"
  },
  mainWrapper: {
    display: "flex",
    justifyContent: 'center',
    width: "auto",
    minHeight: "100vh",
    // background: ' linear-gradient(134deg, rgba(124, 77, 255, 0.02) 37.04%, rgba(125, 79, 253, 0.20) 80.38%)',
    background: 'linear-gradient(135deg, #F4EEFC 0%, #FAEEEC 100%)',
    blur: "100px",
    fontFamily: "'Rubik', sans-serif",
  },
  scndWrapper: {
    width: "100%",
    marginTop: '20px'

  },
  textButtonDiv: {
    paddingInlineStart: '24px',
    height: 'auto',
    alignItems: 'center',
    lineHeight: '40px',
    marginTop: 48
  },
  textButton: {
    color: 'var(--Base-Blue-grey-10, #3C3E49)',
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: 500,
    fontFamily: "Rubik",
  },

  natashaIconDiv: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '30px'
  },

  firstInputFormDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column' as 'column',
    gap: 30,
    padding: '0 15%',
  },
  firstFormStyle: {
    display: 'flex',
    borderRadius: '12px',
    border: '1px solid var(--Blue-Grey-5, #C0C3CE)',
    background: 'var(--0, #FFF)',
    padding: '12px'
  },
  suggestionsMainDiv: {
    padding: "16px 20px",
    gap: '12px',
    backgroundColor: "#fff",
    borderRadius: "0px 16px 16px 16px",
    display: 'flex',
    justifyContent: 'center',
    flexDirection: "column" as "column",
    width: "40%",
    alignSelf: 'stretch'
  },
  suggestionMainSpan: {
    fontFamily: 'Rubik',
    fontSize: '14px',
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '20px',
    letterSpacing: '0.14px',
    gap: '8px',
    color: 'var(--Blue-Grey-10, var(--Base-Blue-grey-10, #3C3E49))'
  },
  suggestionContainer: {
    display: "flex",
    border: "1px solid #F3EBFF",
    padding: "8px 16px",
    borderRadius: '8px',
    color: ' var(--Brand-Purple-5, var(--Base-Purple-5, #6200EA))',
    fontFamily: 'Rubik',
    fontWeight: '400',
    fontStyle: 'normal',
    lineHeight: '20px',
    letterSpacing: '0.14px',
    fontSize: '14px',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  flexdisplay: {
    display: 'flex',
    justifyContent: 'center',
    bottom: 50
  },
  flexdisplayItemAlign: {
    display: 'flex',
    alignItems: 'center'
  },
  eightyWidth: {
    width: '80%'
  },
  pixelWidthNatasha: {
    width: '130px'
  },
  firstInputChat: {
    width: "100%",
    border: "none",
    outline: 'none',
    fontFamily: "Rubik",
    fontSize: '14px',
  },
};
// Customizable Area End
