import React, { Component } from 'react';
import {
  Button,
  Box,
  Chip,
  OutlinedInput,
  MenuItem,
  Select,
  Checkbox,
  FormControl,
  
  IconButton,
  InputBase, SelectChangeEvent , SelectProps
} from '@mui/material';
import {styled} from '@mui/system'
import { MUIStyledCommonProps } from '@mui/system';

type StyledSelectProps = SelectProps<unknown> & MUIStyledCommonProps;
import withStyles from '@mui/styles/withStyles';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import Close from '@mui/icons-material/Close';

const mainStyle = withStyles({
  list: {
    padding: '0px !important',
    maxWidth: '420px',
  },
})

export type FlowData = {
  value: string;
  name: string;
}

type Props = {
  datas: FlowData[];
  selected: string[];
  renderValue: () => JSX.Element;
  onApply: (selected: string[]) => void;
  classes?: Record<string, string>;
} & Record<string, object>

type State = {
  searchKey: string;
  show: boolean;
  selected: string[];
  selectedMirror: string[];
}

type SS = {

}

class _MenuDropdown extends Component<Props, State, SS> {
  constructor(props: Props) {
    super(props);

    this.state = {
      searchKey: '',
      show: false,
      selected: this.props.selected,
      selectedMirror: this.props.selected,
    }
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (nextProps.selected.toString() !== prevState.selectedMirror.toString()) {
      return { selected: nextProps.selected, selectedMirror: nextProps.selected }
    }
    return null
  }

  onOpen = () => {
    this.setState(prev => ({ ...prev, show: true }))
  }

  onClose = () => {
    console.log("ON CLOSE")
    console.log(this.state.selected)
    console.log(this.state.selectedMirror)
    this.setState(prev => ({ ...prev, show: false, selected: prev.selectedMirror }))
  }

  onApply = () => {
    console.log('ON APPLYYYYYY')
    console.log(this.state.selected)
    this.props.onApply(this.state.selected)
    this.onClose()
  }

  onCancel = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    this.onClose()
  }

  onChange = (event: SelectChangeEvent<unknown>) => {
    let data = event.target.value as string[]
    
    data = data.filter(item => item !== undefined && item.trim() !== '');
    this.setState({ selected: data })
  }

  onChipDelete = (item: string) => {
    return () => {
      this.setState(prev => ({ ...prev, selected: prev.selected.filter(val => val !== item) }))
    }
  }

  onClearSelected = () => {
    this.props.onApply([]);
  }

  searchOnChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    event.stopPropagation()
  }

  render() {
    let {
      datas,
      selected,
      renderValue,
      onApply,
      classes,
      ...props
    } = this.props as Props;
    const dataTestId = props['data-test-id']
    delete props['data-test-id']

    return (
      <FormControl
        onClick={(e) => {
          e.stopPropagation()
        }}>
        <MSelect
          {...props}
          multiple
          open={this.state.show}
          onOpen={this.onOpen}
          onClose={this.onClose}
          value={this.state.selected}
          onChange ={this.onChange}
          input={<OutlinedInput id='select-multiple-chip' label='Chip' />}
          displayEmpty={true}
          renderValue={renderValue}
          inputProps={{
            style: {
              display: 'none'
            },
          }}
          SelectDisplayProps={{
            style: {
              padding: 0,
            },
            'data-test-id': dataTestId as never as string,
          } as never as React.HTMLAttributes<HTMLDivElement>}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            PaperProps: {
              style: {
                boxShadow: '0px 8px 32px 0px #0000000F',
                minWidth: 'fit-content',
                transform: 'translate(24px, -8px)',
                borderRadius: 8,
                position: 'absolute'
              },
            },
            classes: {
              list: classes?.list,
            },
          }}
        >
          <div
            style={{
              margin: '15px 0px',
              padding: '0px 15px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                borderRadius: 8,
                backgroundColor: '#fff',
                border: '1px solid #CBD5E1',
                padding: 5,
                paddingRight: 5 + (this.state.selected.length > 0 ? 15 : 0),
              }}
              onClick={e => e.stopPropagation()}
            >
              {
                this.state.selected.length !== 0 && (
                  <IconButton
                    style={{
                      padding: 0,
                      position: 'absolute',
                      top: 0,
                      right: 0,
                    }}
                    size='small'
                    onClick={this.onClearSelected}
                  >
                    <CancelIcon fontSize='inherit' />
                  </IconButton>
                )
              }
              <div style={{
                display: 'flex',
                flexWrap: 'wrap',
              }}>
              {
                this.state.selected
                  .map(value => datas.find(val => val.value === value))
                  .filter((val): val is FlowData => !!val)
                  .map(value => (
                    <Chip
                      key={value.value}
                      label={`${value.name}`}
                      style={{
                        background: '#F0E5FF',
                        margin: 5,
                        fontFamily: 'Inter',
                        fontSize: 12,
                        fontWeight: 400,
                        lineHeight: 18,
                        letterSpacing: 0,
                        color: '#6200EA',
                      }}
                      deleteIcon={<Close fontSize="inherit" style={{color: '#6200EA'}} />}
                      onDelete={this.onChipDelete(value.value)}
                    />
                  ))
              }
              </div>
              <InputBase
                onChange={this.searchOnChange}
                {...(this.state.selected.length === 0 ? {
                    startAdornment: <SearchIcon color='disabled'/>,
                    placeholder: 'Search',
                  } : {})}
              />
            </div>
          </div>
          {datas.map((item) => (
            <MMenuItem
              data-test-id={(dataTestId ?? '')+'-'+item.value}
              key={item.value}
              value={item.value}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  gap: 10,
                  fontFamily: 'Inter',
                }}
              >
                <option value={item.value}>
                  {item.name}
                </option>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <MCheckbox
                    color='primary'
                    checked={this.state.selected.some(val => val === item.value)}
                  />
                </div> 
              </div>
            </MMenuItem>
          ))}
          <Actions>
            <Button
              data-test-id={(dataTestId ?? '')+'-cancel'}
              color='primary'
              style={styles.actionButton}
              onClick={this.onCancel}
            >Cancel</Button>
            <Button
              data-test-id={(dataTestId ?? '')+'-apply'}
              color='primary'
              style={styles.actionButton}
              onClick={this.onApply}
            >Apply</Button>
          </Actions>
        </MSelect>
      </FormControl>
    )
  }
}

const styles = {
  actionButton: {
    color: '#6200EA',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    textTransform: 'none',
  }
} as const;

export const MSelect = styled(Select)({
  '& svg': {
    display: 'none',
  },
  '& fieldset': {
    display: 'none',
  },
  '& div ul': {
    padding: '0px !important',
  },
  '& .MuiSelect-select:focus': {
    backgroundColor: 'unset !important',
  },
})

const MMenuItem = styled(MenuItem)({
  backgroundColor: 'unset !important',
  '& .MuiTouchRipple-root': {
    display: 'none',
  },
})

const MCheckbox = styled(Checkbox)({
  '&.Mui-checked': {
    color: '#6200EA',
  },
})

const Actions = styled('div')({
  borderTop: '1px solid #E2E8F0',
  textAlign: 'right',
  padding: '8px 25px',
  backgroundColor: '#fff',
  position: 'sticky',
  bottom: 0,
  zIndex: 2,
})

const MenuDropdown = mainStyle(_MenuDropdown)

export default MenuDropdown
