//@ts-nocheck
// Customizable Area Start
import React from "react";
import {
  natashaIcon, personIcon, mic
} from "../src/assets";
import ContentManagementController, {
  Props,
} from "./ContentManagementController";
// Customizable Area End


export default class NatashaChatScreen extends ContentManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <div className="mainWrapperNatasha" style={webStyle.mainWrapper}>
        
      { <div style={webStyle.ChatScreenDivContainer}>
        <div style={webStyle.mainWrapper}>
          <div style={webStyle.scndScreenContainer}>

            <div style={webStyle.scndScreenContainerDiv} >
            <div className="aiIconNatashaPage"><div><div></div></div> </div>
              <div style={webStyle.chatfromNatashaDiv}>
                <div style={webStyle.chatfromNatashaDivScnd}>How are You?</div>
                <div style={webStyle.timeChat}>2:29pm</div>
              </div>
            </div>


            {this.state?.chat.map((chat, index) => {
              return (
                <div style={webStyle.chatmapMainDiv} key={index} >
                  <div style={webStyle.userBox}>
                    <img src={personIcon} alt="natashaIcon" style={webStyle.iconForChat} />
                  </div>
                  <div style={webStyle.chatFromUserMainDiv}>
                    <div style={webStyle.chatFromUserScndDiv}>
                      {chat.message}
                    </div>
                    <div style={webStyle.timeChat}>2:30pm</div>
                  </div>
                </div>
              );
            })}

              {
                (this.state.chat.length && this.state.natashaTyping) ? (
                  <div style={webStyle.scndScreenContainerDiv}>
                    <div className="aiIconNatashaPage"><div><div></div></div> </div>
                    <div style={webStyle.chatfromNatashaDiv}>
                      <div style={webStyle.chatfromNatashaDivScnd}>Natasha will reply soon...</div>
                      <div style={webStyle.timeChat}>2:30pm</div>
                    </div>
                  </div>
                ) : (
                  <div style={webStyle.scndScreenContainerDiv} >
                  <div ><img src={natashaIcon} alt="natashaIcon" style={webStyle.iconForChat} /></div>
                  <div style={webStyle.natashaTyping}>...</div>
                  </div>
                )
              }

            <form style={webStyle.scndFormStyle} data-test-id='onSubmitChat'
              onSubmit={/* istanbul ignore next */(e) => this.onSubmitChat(e)}
            >
              <input data-test-id="startTypingField" value={this.state.inputState} type="text" style={webStyle.scndInput} placeholder="Enter query here" onChange={/* istanbul ignore next */ (e) => this.inputChange(e)} />
              <img src={mic} alt="Natasha Icon" style={webStyle.NatashaIcon} />
            </form>
          </div>
        </div>
      </div>}
      
    </div>
      // Customizable Area End

    );
  }
}
// Customizable Area Start

const Styles = {
  ImgTagStyle: {
    width: '100px',
    height: '100px',
    padding: '10px'
  },
}

const webStyle = {
  NatashaIcon:{
    display: "inline"
  },
  mainWrapper: {
    display: "flex",
    justifyContent: 'center',
    width: "auto",
    minHeight: "100vh",
    // background: ' linear-gradient(134deg, rgba(124, 77, 255, 0.02) 37.04%, rgba(125, 79, 253, 0.20) 80.38%)',
    background: 'linear-gradient(135deg, #F4EEFC 0%, #FAEEEC 100%)',
    blur: "100px",
    fontFamily: "'Rubik', sans-serif",
  },
  natashaTyping:{
    padding: '15px',
    backgroundColor: '#fff',
    borderRadius: '10px',
    marginRight: 'auto',
  },

  scndScreenContainer: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    width: '80%',
    margin: '30px',
    marginTop: 'auto', // marginBlockStart in inline styles is equivalent to marginTop in CSS
  },

  scndScreenContainerDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    alignSelf: "baseline",
    marginBottom: "40px",
    color: "#3C3E49",
    position: "relative",
    maxWidth: '50%',
  },
  chatfromNatashaDiv: {
    background: "#ffffff",
    padding: "16px 20px 8px 20px",
    borderRadius: "0px 16px 16px 16px",
    marginLeft: '60px',
  },
  chatfromNatashaDivScnd: {
    background: "#fff",
    padding: "10px 0px 8px 0px",
  },

  chatmapMainDiv:{
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    alignSelf: "end",
    marginBottom: "40px",
    flexDirection: "row-reverse" as "row-reverse",
    maxWidth: '50%',
    position: 'relative',
    paddingRight: '67px'
  },
  userBox:{
    position: 'absolute',
    top: '0px',
    right: '0px'
  },
  chatFromUserMainDiv:{
    display: "flex",
    flexDirection: "column" as "column",
    background: "#7C4DFF",
    borderRadius: "16px 0px 16px 16px",
    padding: "16px 20px 8px 20px",
    color: "#fff",
    marginRight: '8px'
  },
  chatFromUserScndDiv:{
    background: "#7C4DFF",
  padding: "16px 20px 8px 0px",
  borderRadius: "0px 16px 16px 16px"
  },
  scndFormStyle:{
    display: "flex",
    borderRadius: '12px',
    border: '1px solid var(--Blue-Grey-5, #C0C3CE)',
    background: 'var(--0, #FFF)',
    padding: "12px",
    width: '80%',
    margin: 'auto',
  },
  scndInput:{
    width: "100%",
    border: "none",
    outline: 'none',
    alignItems: 'center'
  },
  ChatScreenDivContainer:{
  width:'100%',
  height:'100vh'
  },
  iconForChat:{
    width: '48px',
    height: '48px',
    marginRight: '15px'
  },
  timeChat:{
    fontSize: '12px',
    textAlign: 'end' as "end",
  },
  
};
// Customizable Area End
