export const group_Logo = require("../assets/group_logo_.png");
export const layout_Corners_Dashboard = require("../assets/layout-corners-dashboard.png");
export const search_Icon = require("../assets/search.png");
export const natasha_Icon = require("../assets/natasha.png");
export const retouch_Graph = require("../assets/retouch-graph.png");
export const group_Icon = require("../assets/group_.png");
export const high_Icon = require("../assets/redIcon.png");
export const medium_Icon = require("../assets/mediumIcon.png");
export const low_Icon = require("../assets/lowIcon.png");
export const dashboard_Icon = require("../assets/dashboard.png");
export const trial_Icon = require("../assets/trials.png");
export const explorer_Icon = require("../assets/explorer.png");
export const filter_Icon =require("../assets/filterIcon.png");
export const funnel_Icon = require("../assets/funnel.png");
export const star_outline = require("../assets/star_outline.png");
export const star = require("../assets/star.png");
export const upArrowSection = require("../assets/keyboard_arrow_up.png");
export const downArrowSection = require("../assets/keyboard_arrow_down.png");

