import React from "react";
// Customizable Area Start
import {
  InputBase,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Badge,
  Tooltip,
  IconButton,
  ClickAwayListener,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  styled,
  Box,
  Divider,
  Grid
} from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import { low_Icon, medium_Icon, high_Icon, filter_Icon, funnel_Icon, star_outline, star } from "../src/assets"
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Truncate from "../../../components/src/Truncate.web";

import {
  DashboardCard,
} from "./DashboardController.web";
// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";
import { StyleSheet } from "react-native";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  getHrefValue = (isReadOnly: boolean | null, href: string) => {
    if (isReadOnly) {
      return undefined
    }
    return href
  }
  getViewStyle = (isReadOnly: boolean | null) => {
    let style = webStyles.viewCard
    if (isReadOnly) {
      return {...style, ...webStyles.disabled, ...webStyles.normalCursor}
    }
    return style
  }
  descriptionData = (value: any) => {
    return (
      <Truncate text={value.description} />
    )
  }
  nameSection = (value: DashboardCard, sectionId: string) => {
    return (
      <div style={{ display: 'flex', alignItems: 'flex-start', borderBottom: '1px solid #F3EBFF', paddingBottom: '10px', flexDirection: 'row', justifyContent: 'space-between' }}>
        <span style={{
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: "400",
          color: "#3C3E49",
          fontFamily: "'Rubik', sans-serif",
          textAlign: 'left',
          lineHeight: '24px',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis'
        }}>
          {value.name}
        </span>
        <img
          data-test-id={`fav-${value.id}`}
          src={this.state.favourites.has(value.id.toString()) ? star : star_outline}
          style={{
            ...{ width: '18px', cursor: 'pointer' },
            ...( value.allow_read_only ? webStyles.normalCursor : {} ),
          }}
          onClick={() => {
            if (value.allow_read_only) {
              return
            }
            this.handleFav(value, sectionId)
          }}
        />
      </div>
    )
  }
  optionSelect = () => {
    return (
      <span style={{ fontSize: '14px', fontFamily: 'Inter', lineHeight: '22px', fontWeight: '600', color: '#475568' }}>{'Popularity  '}
      {/* <span style={{position: 'absolute', top: '-5px', right: '-5px', width:'8px', height: '8px', backgroundColor: 'red', borderRadius: '50%'}}/> */}
      </span>
    )
  }
  dashboardSelectData = () => {
    return (
      <div style={{ marginTop: 35, display: 'flex', flexDirection: 'column', gap: 20 }}>
        {this.state.DashboardCardsData.map((item, index) => {
          const selected = this.state.accordionExpanded.includes(String(item.id))
          return (
            <Accordion
              key={index+String(item.id)}
              data-test-id={`accordion-${item.id}`}
              style={{
                backgroundColor: "#FFFFFF",
                borderRadius: '16px',
                boxShadow: 'unset',
                position: 'unset',
                margin: 0,
              }}
              expanded={selected}
              onChange={this.toggleExpand(String(item.id))}
            >
              <MAccordionSummary
                id={this.convertToSnakeCase(item.attributes.name)}
                expandIcon={<ExpandMore />}
                style={{
                  padding: '0px 15px'
                }}
              >
                <p style={{ fontFamily: 'Inter', fontWeight: '500', fontSize: 18, color: '#3C3E49', margin: 0, textShadow: (selected ? '0px 4px 4px #00000040' : 'unset') }}>{item.attributes.name}</p>
              </MAccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3}>
                  {item.attributes.dashboard_cards.map((value) => {
                    return (
                      <Grid key={value.id+value.name} item xs={12} md={3}>{
                        <div
                          style={{
                            borderWidth: 1,
                            border: '1px solid #B388FF',
                            backgroundColor: "#fff",
                            borderRadius: "8px 8px 8px 24px",
                            padding: '12px 16px 20px 16px',
                            minHeight: '223px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                          }}>
                          <div>
                            <Tooltip title={value.name} arrow style={{ backgroundColor: '#3C3E49', height: '40px', width: '91px', borderRadius: '3px' }}>
                            {this.nameSection(value, String(item.id))}
                            </Tooltip>
                            {this.descriptionData(value)}
                          </div>
                          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap', gap: 8 }}>
                            {value.popularity === "High" && (
                              <Tooltip title='Popularity' arrow style={{ backgroundColor: '#3C3E49', height: '40px', width: '91px', borderRadius: '3px' }}>
                                <div style={{...webStyles.priorityCard, ...webStyles.highPrio}}>
                                  <img src={high_Icon}
                                    style={{ width: '18px' }} />
                                  <span style={{
                                    fontFamily: 'Rubik',
                                    lineHeight: '16px', fontSize: '12px', fontWeight: '500'
                                  }}>{value.popularity}</span>
                                </div></Tooltip>)}
                            {value.popularity === "Medium" && (
                              <Tooltip title='Popularity' arrow >
                                <div style={{...webStyles.priorityCard, ...webStyles.mediumPrio}}>
                                  <img src={medium_Icon}
                                    style={{ width: '18px', }} /><span style={{
                                    fontFamily: 'Rubik',
                                      lineHeight: '16px', fontSize: '12px', fontWeight: '500'
                                    }}>{value.popularity}</span>
                                </div></Tooltip>)}
                            {value.popularity === "Low" && (
                              <Tooltip title='Popularity' arrow style={{ backgroundColor: '#3C3E49' }}>
                                <div style={{...webStyles.priorityCard, ...webStyles.lowPrio}}>
                                  <img src={low_Icon}
                                    style={{ width: '18px', }} /><span style={{
                                    fontFamily: 'Rubik',
                                      lineHeight: '16px', fontSize: '12px', fontWeight: '500'
                                    }}>{value.popularity}</span>
                                </div></Tooltip>)}

                            {value.url_type === "Open In Same Tab" ? <div data-test-id='getScrollID' style={this.getViewStyle(value.allow_read_only)} onClick={() => {
                              if (value.allow_read_only) {
                                return
                              }
                              this.sendAnalyticsTrack(`view.${this.convertToSnakeCase(item.attributes.name)}.${this.convertToSnakeCase(value.name)}`, "892");
                              value.url_type === "Open In Same Tab" && this.showIframeId(value.id.toString())
                            }}>
                              View
                            </div> : <a href={this.getHrefValue(value.allow_read_only, value.url)} target="_blank" style={this.getViewStyle(value.allow_read_only)}>
                              <span
                                style={{
                                  fontSize: "14px", fontWeight: '600', fontStyle: "normal", lineHeight: '20px', letterSpacing: 0.14, color: '#3C3E49', fontFamily: 'Rubik'
                                }}
                              >View</span>
                            </a>}
                          </div>
                        </div>
                      }</Grid>
                    )
                  })}
                  {
                    (item.attributes.dashboard_cards_size.dashboard_cards_count > item.attributes.dashboard_cards.length) && (
                      <Button data-test-id={`loadMore-${this.convertToSnakeCase(item.attributes.name)}`} onClick={() => { this.handleLoadMore(String(item.id)); this.sendAnalyticsTrack("load_more." + this.convertToSnakeCase(item.attributes.name), "892"); }} style={{ backgroundColor: '#fff', width: '100%', border: '1px solid #e8dbff', boxShadow: 'none', margin: '15px', padding: '14px', borderRadius: '8px', fontFamily: "'Rubik', sans-serif", fontWeight: '500', fontSize: '14px', lineHeight: '20px', textTransform: 'none', letterSpacing: '1px', color: "#6301ea" }} variant="contained">Load more</Button>
                    )
                  }
                  {item.attributes.dashboard_cards.length > 8 && (
                    <Button data-test-id='loadLessID' href={`#${this.convertToSnakeCase(item.attributes.name)}`} onClick={() => { this.handleLoadLess(item); this.sendAnalyticsTrack("load_less." + this.convertToSnakeCase(item.attributes.name), "892"); }} style={{ backgroundColor: '#fff', width: '100%', border: '1px solid #e8dbff', boxShadow: 'none', margin: '15px', borderRadius: '8px', fontFamily: "'Rubik', sans-serif", fontWeight: '500', fontSize: '14px', lineHeight: '20px', textTransform: 'none', letterSpacing: '1px', padding: '14px', color: "#6301ea" }} variant="contained">Load less</Button>
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          )
        })}
      </div>
    )
  }

  renderDasboardData = () => {
    return (
      <div style={{ paddingLeft: '25', marginBottom: 50, paddingRight: '24', width: '100%', }}>
          <Box
            display='flex'
            flexDirection='row'
            rowGap={20}
            alignItems='center'
            justifyContent='space-between'
            flexWrap='wrap'
          >
            <span style={{ fontSize: 20, fontWeight: "300", fontStyle: 'normal', fontFamily: "Inter", color: '#3C3E49' }}>Dashboard</span>
            <Box
              display='flex'
              flexDirection='row'
              flexGrow={0.8}
              rowGap={40}
              alignItems='center'
              flexWrap='wrap'
            >
              <div style={{
                paddingLeft: '5px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                borderRadius: '12px',
                border: '1px solid #CBD5E1',
                backgroundColor: '#fff',
                flexGrow: 1,
                minWidth: 'fit-content',
                marginLeft: 40,
              }}>
                <div style={{
                  padding: '4px',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                  <SearchIcon style={{ color: '#94A3B8' }} />
                </div>
                <InputBase
                  value={this.state.searchValue}
                  placeholder="Search by keyword"
                  onChange={this.handleSearchChange}
                  onClick={this.handleSearhClick}
                  inputProps={{ 'aria-label': 'search' }}
                  style={{ height: '44px', width: 'calc(100% - 12px)', fontFamily: 'Lato', fontSize: '16px', }}
                  data-test-id='search'
                />
                {
                  this.state.searchValue.length > 0 ?
                    (<IconButton
                    style={{
                        padding: '4px',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    onClick={this.handleCancelSearch}
                    data-test-id='cancel-search'
                    size="large">
                      <CloseIcon fontSize='inherit' style={{ color: '#94A3B8' }} />
                    </IconButton>) : (<></>)
                }
              </div>
              <Box
                display='flex'
                flexDirection='row'
                rowGap={40}
                alignItems='center'
                flexWrap='wrap'
              >
                <ClickAwayListener onClickAway={this.handleClosePopularity}>
                  <MenuTooltip
                    open={this.state.showDropdown}
                    title={
                      <React.Fragment>
                        <div style={webStyles.tooltipBody} className="menu-tooltip-content">
                          <FormGroup style={{marginBottom: '16px'}}>
                         <FormControlLabel 
                            labelPlacement="start" 
                            data-test-id='popularity-all'
                            style={{ alignItems: 'center', justifyContent: 'space-between' }} 
                            value="" 
                            control={<Checkbox checked={this.state.selectedOption.length === 3}  onChange={(e)=>{this.handleOptionSelect(e.target.value)}} style={{ color: '#6200EA' }} />} 
                            label={<span style={{ fontFamily: 'Rubik' }}>All</span>} 
                          />
                          <Divider variant="middle"/>
                          <FormControlLabel 
                            labelPlacement="start" 
                            data-test-id='popularity-high'
                            style={{ alignItems: 'center', justifyContent: 'space-between' }} 
                            value="High" 
                            control={<Checkbox checked={this.state.selectedOption.includes('High')}  onChange={(e)=>{this.handleOptionSelect(e.target.value)}}  style={{ color: '#6200EA' }} />} 
                            label={<span style={{ fontFamily: 'Rubik' }}>High</span>} 
                          />
                          <Divider variant="middle"/>
                          <FormControlLabel 
                            labelPlacement="start" 
                            data-test-id='popularity-medium'
                            style={{ alignItems: 'center', justifyContent: 'space-between' }} 
                            value="Medium" 
                            control={<Checkbox checked={this.state.selectedOption.includes('Medium')}  onChange={(e)=>{this.handleOptionSelect(e.target.value)}}  style={{ color: '#6200EA' }} />} 
                            label={<span style={{ fontFamily: 'Rubik' }}>Medium</span>} 
                          />
                          <Divider variant="middle"/>
                          <FormControlLabel 
                            labelPlacement="start" 
                            data-test-id='popularity-low'
                            style={{ alignItems: 'center', justifyContent: 'space-between' }} 
                            value="Low" 
                            control={<Checkbox checked={this.state.selectedOption.includes('Low')}  onChange={(e)=>{this.handleOptionSelect(e.target.value)}}  style={{ color: '#6200EA' }} />} 
                            label={<span style={{ fontFamily: 'Rubik' }}>Low</span>} 
                          />
                          {/* </RadioGroup> */}
                          </FormGroup>

                          <div style={{display: 'flex', borderTop: '1px solid rgb(203, 213, 225)', justifyContent: 'right', alignItems: 'center', width: '100%', position: 'absolute',bottom: '0px', right: '0px', backgroundColor: 'white'}}>
                          
                            
                            <Button
                            data-test-id={'popularity-apply'}
                            color='primary'
                            style={{color: '#6200EA',
                              fontFamily: 'Rubik',
                              fontSize: '16px',
                              fontWeight: 500,
                              lineHeight: '24px',
                              textTransform: 'none', zIndex: 5}}
                            onClick={()=>{
                                this.handleApplyPopularity()
                            }}
                          >Apply</Button>
                          </div>
                          
                        </div>
                      </React.Fragment>
                    }
                    placement="bottom-end"
                    
                  >
                    <div data-test-id='popularity-filter' style={{ display: 'flex', flexDirection: 'row', alignItems: 'right', cursor: 'pointer', marginLeft: 40}} onClick={this.handleDropdownToggle}>
                    <CustomBadge variant="dot" invisible={this.state.selectedOption.length <= 0 || this.state.selectedOption.length >= 3}  anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}>
                      <img src={filter_Icon} alt="Priority Icon" style={{ width: '24px', height: '24px', paddingRight: '10px' }} />
                      {this.optionSelect()}
                      </CustomBadge>
                    </div>
                  </MenuTooltip>
                </ClickAwayListener>
                <ClickAwayListener
                  onClickAway={this.handleCloseSection}
                >
                  
                  <MenuTooltip
                    open={this.state.showSection}
                    placement="bottom-end"
                    title={
                      <React.Fragment>
                        <div style={webStyles.tooltipBody} className="menu-tooltip-content">
                          
                          <FormGroup style={{marginBottom: '16px'}}>
                            <FormControlLabel
                              data-test-id='section-all'
                              labelPlacement='start'
                              style={{ alignItems: 'center', justifyContent: 'space-between' }}
                              value=''
                              control={<Checkbox onChange={(e)=>{this.handleSectionSelect(e.target.value)}} checked={this.state.selectedSection.length === this.state.DashboardCardsInit.length} style={{ color: '#6200EA' }} />}
                              label={<span style={{ fontFamily: 'Rubik' }}>All</span>}
                            />
                            <Divider variant="middle"/>

                            {this.state.DashboardCardsInit.map((val, idx) => (
                              <>
                              <FormControlLabel
                                data-test-id={`section-${val.id}`}
                                labelPlacement='start'
                                style={{ alignItems: 'center', justifyContent: 'space-between' }}
                                value={val.id}
                                control={<Checkbox checked={this.state.selectedSection.includes(val.id as string)} onChange={(e)=>{this.handleSectionSelect(e.target.value)}} style={{ color: '#6200EA' }} />}
                                label={<span style={{ fontFamily: 'Rubik' }}>{val.attributes.name}</span>}
                                key={`${idx}-${val.id}`}
                                />
                            <Divider variant="middle"/>
                                </>

                            ))}
                          </FormGroup>
                          <div style={{display: 'flex', borderTop: '1px solid rgb(203, 213, 225)', justifyContent: 'right', alignItems: 'center', width: '100%', height: '40px', position: 'absolute',bottom: '0px', right: '0px', backgroundColor: 'white'}}>
                          
                            
                            <Button
                            data-test-id={'section-apply'}
                            color='primary'
                            style={{color: '#6200EA',
                              fontFamily: 'Rubik',
                              fontSize: '16px',
                              fontWeight: 500,
                              lineHeight: '24px',
                              textTransform: 'none', zIndex: 5}}
                            onClick={()=>{
                              this.handleApplySections()
                             }}>Apply</Button>
                          </div>
                          
                          {/* </RadioGroup> */}
                        </div>
                      </React.Fragment>
                    }
                  >
                    <div
                      data-test-id='section-filter'
                      style={{ marginLeft: 40, display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer', }}
                      onClick={this.handleSectionToggle}
                    >
                      <CustomBadge variant="dot" invisible={this.state.selectedSection.length <= 0 || this.state.selectedSection.length >= this.state.DashboardCardsInit.length}  anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}>
                      <img src={funnel_Icon} alt="Section Icon" style={{ width: '24px', height: '24px', paddingRight: '10px' }} />
                      <span
                        style={{
                          fontSize: '14px',
                          fontFamily: 'Inter',
                          lineHeight: '22px',
                          fontWeight: '600',
                          color: '#475568',
                        }}
                      >Section</span>
                      </CustomBadge>
                    </div>
                  </MenuTooltip>
                </ClickAwayListener>
                <div
                  data-test-id='collapse-expand'
                  style={{ marginLeft: 40, display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer', }}
                  onClick={this.toggleExpandAll}
                >
                  <span
                    style={{
                      fontSize: '14px',
                      fontFamily: 'Inter',
                      lineHeight: '22px',
                      fontWeight: '600',
                      color: '#475568',
                      width: 80,
                      textAlign: 'right'
                    }}
                  >{this.state.accordionExpanded.length === 0 ? 'Expand All' : 'Collapse All'}</span>
                </div>
              </Box>
            </Box>
          </Box>
        {this.dashboardSelectData()}
      </div>
    );
  }


  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      //Merge Engine DefaultContaine
      <div style={{
        display: 'flex',
        height: '100%',
        minHeight: "100vh",
        padding: '25px',
        backgroundImage: `linear-gradient(0deg, rgb(239, 230, 240) 0%, rgb(239, 230, 240) 17%, rgb(245, 233, 246) 33%, rgb(247, 239, 244) 50%, rgb(249, 239, 242) 67%, rgb(248, 243, 247) 83%, rgb(248, 244, 252) 100%)`
      }}>
        {this.renderDasboardData()}
      </div>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MenuTooltip = withStyles({
  tooltip: {
    padding: "0 0 !important",
    backgroundColor: "unset !important",
    color: "black !important",
  }
})(Tooltip);

const CustomBadge = styled(Badge)({
  '& .MuiBadge-dot': {
    backgroundColor: '#6200EA',
    minWidth: '5px',
    width: '5px',
    height: '5px'
  },
})
const MAccordionSummary = styled(AccordionSummary)({
  padding: 0,
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
})

const styles = StyleSheet.create({
  SafeAreaView: {
    backgroundColor: '#f2f2f2',
    flexDirection: "row",
    display: 'flex'
  },
  navBtnWraper: {
    flexDirection: "row",
    width: 158,
    height: 44,
    paddingHorizontal: 20,
    paddingVertical: 9,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,
    borderWidth: 1,
    borderColor: "#E2E8F0",
    position: "absolute",
    top: -44,
    left: 24
  },

  navBtn: {
    flexDirection: "row",
    width: 150,
    height: 36,
    paddingHorizontal: 20,
    paddingVertical: 9,
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#F0E5FF",
    borderRadius: 8
  }
});

const webStyles = {
  menuBarStyleWrapperBottom: {
    listStyleType: 'none',
    padding: '0px',
  },
  menuBarStyleWrapperTop: {
    listStyleType: 'none',
    padding: '0px',
    marginTop: "25px",
  },
  menuBarStyle: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '25px',
  },
  container: {
    display: "grid",
    gridTemplateColumns: "3fr 7fr",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    width: "32rem",
    padding: "0.5rem 1rem",
    minHeight: "12rem",
    gap: "2rem"
  },
  totalCandidates: {
    display: "grid",
    gridTemplateRows: "3fr 7fr",
    alignItems: "start",
    justifyContent: "center",
    justifyItems: "center",
    color: "black",
    margin: "1rem 0rem"
  },
  candidatesFontSize: {
    fontSize: "1rem"
  },
  btn: {
    backgroundColor: "blue",
    color: "white",
    borderRadius: "0",
    marginTop: "0.5rem",
    padding: "0.125rem"
  },
  fontSizeForType: { fontSize: "1 rem" },
  mediumFontSizeForType: { fontSize: "3rem" },
  itemStyle: { margin: "1rem 0rem" },
  type: { fontSize: "0.8rem", textTransform: "capitalize" },
  titletext: {
    color: "grey",
    fontWeight: "lighter",
    fontSize: "3rem"
  },
  typeText: { fontSize: "0.8rem", textTransform: "capitalize" },
  tooltipBody: {
    minWidth: '120px',
    width: 'fit-content',
    padding: '20px',
    borderRadius: '8px',
    backgroundColor: '#FFFFFF',
    gap: '24px',
    maxHeight: '70vh',
    overflowY: 'auto',
  },
  priorityCard: {
    borderRadius: '4px',
    padding: '5px 8px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    cursor: 'pointer',
  },
  highPrio: {
    backgroundColor: '#FEE5E2',
    border: '1px solid #D50000',
  },
  mediumPrio: {
    backgroundColor: '#FFF9E1',
    border: '1px solid #FFAB00',
  },
  lowPrio: {
    backgroundColor: '#E5E7FF',
    border: '1px solid #304FFE',
  },
  viewCard: {
    color: '#6200EA',
    backgroundColor: '#F3EBFF',
    borderRadius: '8px',
    width: '100px',
    padding: '12px 15px',
    fontSize: '14px',
    fontWeight: '500',
    textAlign: 'center',
    lineHeight: '20px',
    fontFamily: "'Rubik', sans-serif",
    cursor: 'pointer',
    textDecoration: 'none',
  },
  disabled: {
    color: 'unset',
    backgroundColor: '#d9d9d9',
  },
  normalCursor: {
    cursor: 'none',
  },
} as const;

// Customizable Area End
