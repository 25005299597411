import React from "react";

// Customizable Area Start
// Customizable Area End

import AnalyticsController, {
  Props
} from "./AnalyticsController.web";

import "./Analytics.css"

// Customizable Area Start


const webStyle = {
  chart: {
    height: "110%",
    width: "100%",
  },
  mainWrapper: {
    minHeight: "100vh",
    blur: "100px",
    fontFamily: "'Rubik', sans-serif",
    width: "auto",
    justifyContent: 'center',
    display: "flex",
    background: 'linear-gradient(135deg, #F4EEFC 0%, #FAEEEC 100%)',
  },
  flexdisplay: {
    marginTop: "100px",
    paddingBottom: "200px",
    paddingLeft: "30px",
  },
  scndWrapper: {
    width: "100%",
    marginTop: '20px',

  },
  textButton: {
    color: 'var(--Base-Blue-grey-10, #3C3E49)',
    fontSize: '32px',
    fontWeight: 500,
    fontFamily: "Rubik",
    fontStyle: 'normal',
  },
  chatfromNatashaDiv: {
    background: "#ffffff",
    borderRadius: "0px 16px 16px 16px",
    padding: "16px 20px 8px 20px",
  },
  textButtonDiv: {
    alignItems: 'center',
    lineHeight: '40px',
    paddingInlineStart: '12px',
    height: 'auto',
  },
  scndScreenContainer: {
    flexDirection: 'column' as 'column',
    marginTop: 'auto',
    alignItems: 'center',
    width: '80%',
    margin: '30px',
    display: 'flex',
  }
  

};
// Customizable Area End


export default class AnalyticsChart4 extends AnalyticsController {
  // private chartRef: React.RefObject<SVGSVGElement>;
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // this.chartRef = React.createRef()
    // Customizable Area End
  }

  // Customizable Area Start
  // async componentDidMount() {
  //   let ac4 = await 
    
  // }
  // Customizable Area End


  render() {
    return (
      // Customizable Area Start
          <div style={webStyle.flexdisplay} >
            <svg ref={this.chartRef2} width={1350} height={1200}>
              <g />
            </svg>

          </div>
      // <div style={webStyle.mainWrapper}>
      //   <div style={webStyle.scndWrapper}>

      //   </div>
      // </div >
      // Customizable Area End
    );
  }
}


