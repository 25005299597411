import React from "react";

// Customizable Area Start
import {
  drop_down_icon, country, calendar_Icon
} from "../src/assets";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction, Divider, ClickAwayListener, AppBar, InputBase, Grid, Checkbox
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Popover from '@mui/material/Popover';
import MenuDropdown from '../../../components/src/MenuDropdown.web';
import {
  Data
} from "./AnalyticsController.web";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import * as d3 from 'd3';

const webStyle = {
  mainWrapper: {
    fontFamily: "'Rubik', sans-serif",
    justifyContent: 'center',
    blur: "100px",
    width: "auto",
    height: "auto",
    display: "flex",
    background: 'linear-gradient(135deg, #F4EEFC 0%, #FAEEEC 100%)',
  },
  chart: {
    width: "100%",
    height: "110%",
  },
  scndWrapper: {
    marginTop: '10px',
    width: "100%",
    height: '100%',
  },
  textButton: {
    fontSize: '32px',
    color: 'var(--Base-Blue-grey-10, #3C3E49)',
    fontFamily: "Rubik",
    fontStyle: 'normal',
    fontWeight: 500,
  },
  textButtonDiv: {
    lineHeight: '40px',
    height: 'auto',
    paddingInlineStart: '12px',
    alignItems: 'center',
  },
  chatfromNatashaDiv: {
    borderRadius: "0px 16px 16px 16px",
    background: "#ffffff",
    padding: "16px 20px 8px 20px",
  },
  scndScreenContainer: {
    alignItems: 'center',
    flexDirection: 'column' as 'column',
    marginTop: 'auto',
    width: '80%',
    margin: '30px',
    display: 'flex',
  },
  flexdisplay: {
    paddingLeft: "30px",
    overflow: 'auto',
    height: '100%'
  },
  svgContainer: {
    width: "100%",
    height: "100%",
  },

};
// Customizable Area End

import AnalyticsController, {
  Props,
  configJSON
} from "./AnalyticsController.web";

export default class Analytics extends AnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const localStorageUserToken = localStorage.getItem("userInfo")
    const parseLocalStorage = JSON.parse(localStorageUserToken ?? "{}") || {}
    this.setState({token: parseLocalStorage.session_token})
    this.getEventNames(parseLocalStorage.session_token)
    this.getUniqueStages(parseLocalStorage.session_token)
    this.getBoxesFilter(parseLocalStorage.session_token)
    this.getFlowsFilter(parseLocalStorage.session_token)

    this.drawChart2();
  }
  componentDidUpdate(prevProps: Readonly<Props>, prevState: any, snapshot?: any): void {
    if(this.state.stages !== prevState.stages){
      console.log("UPDATED")
      console.log(this.state.stages)
      this.removeAllBuildcardDrawings();
      this.drawChart2()
    }
    if(this.state.selectedStage !== prevState.selectedStage){
      console.log("changed")
      console.log(this.state.selectedStage)
    }
    if(this.state.boxes !== prevState.boxes){
      const nodes = this.fetchStage2()
      this.adjustWidth(nodes);
      this.setState({boxNodes: nodes})
      this.drawNodes(this.state.svg, nodes, this.state.availableColumns, this.state.columnWidth, 'stageboxes')
      this.drawBuildcardPercentage(nodes, 'stageboxpercentagetext')
      this.drawEventNameText(nodes, 'stageboxeventnametext')
      this.drawLinks(nodes, 'stageboxeslinks');
    }
    if(this.state.flows !== prevState.flows){
      const nodes = this.fetchStage3()
      this.adjustWidth(nodes);
      this.setState({flowNodes: nodes})
      this.drawNodes(this.state.svg, nodes, this.state.availableColumns, this.state.columnWidth, 'stageboxflows')
      this.drawBuildcardPercentage(nodes, 'stageboxflowpercentagetext')
      this.drawEventNameText(nodes, 'stageboxfloweventnametext')
      this.drawLinks(nodes, 'stageboxflowslinks')
    }

    if(this.state.selectedStage !== prevState.selectedStage){
      this.setState({stages: this.filterStages(this.state.unfilteredStages)})
    }
    if(this.state.selectedSurfaceData !== prevState.selectedSurfaceData){
      this.setState({boxes: this.filterBoxes(this.state.unfilteredBoxes)})
    }
    if(this.state.selectedFlowData !== prevState.selectedFlowData){
      this.setState({flows: this.filterFlows(this.state.unfilteredFlows)})
    }
    if(this.state.flowNodes !== prevState.flowNodes){
      console.log("Flow nodes");
      console.log(this.state.flowNodes);
    }
    if(this.state.buildcardBox !== prevState.buildcardBox){
      console.log(this.state.buildcardBox)
      
    }
    if(this.state.buildcardFlow !== prevState.buildcardFlow){
      
        
    }
  
  }

  drawChart2 = () => {
    this.setState({ chartReady: false });
    const svgElement = this.chartRef3.current as SVGSVGElement;
    if (!svgElement) return;

    const svg = d3.select(svgElement);
    
    this.setState({svg: svg});

    

    // Compute node positions
    const nodeWidth = 35;
    const spacing = 10;
    const largestNode = this.findStageLargestColumn()
    
    const nodes = this.state.stages.map((d:any, index:number) => ({
        ...d,
        nX0: index * (nodeWidth + spacing),
        nX1: index * (nodeWidth + spacing) + nodeWidth,
        nY0: 90,
        nY1: 90+this.calculateNodeHeight("stage", d, largestNode),
        column: index,
        className: "stages",
        name: d.stage,
        dewey_code: d.stage,
    }));
    
    if(nodes.length <= 0)return;
    const availableColumns = nodes.length;
    this.setState({availableColumns: availableColumns, stageNodes: nodes})

    if (availableColumns > 8) {
        this.setState({ sChartWidth: this.state.sChartWidth + this.state.columnWidth * (availableColumns - 8) });
    }
    
    const headers = this.state.stages.map((d:any, index:number) => ({
      ...d,
      nX0: index * (this.state.columnWidth + spacing),
      nX1: index * (this.state.columnWidth + spacing) + this.state.columnWidth,
      nY0: 0,
      nY1: 40,
      column: index
    }));
    
    svg.selectAll("*").remove();
    
    this.drawHeaders(svg, headers, availableColumns, this.state.columnWidth)
    this.drawNodes(svg, nodes, availableColumns, this.state.columnWidth, "stages");
    this.drawBuildcardPercentage(nodes, 'stagepercentagetext')
    this.drawEventNameText(nodes, 'stageeventnametext')
    this.drawLinks(nodes, 'stageslinks')
    this.setState({ chartReady: true });
  }

  a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  renderSelectedView = (item: Data, allItem: Data[], allSelected: string[], emptySub: string) => {
    return () => (
        item ? (<div
          key={item.value}
          style={{
            display: 'flex',
            gap: 5,
          }}
        >
          <div style={styles.renderSelectedView}>
            <span>{`${item.name} ${item.value}`}</span>
            <img src={drop_down_icon} alt="drop_down_icon" />
          </div>
          <div style={styles.renderSelectedView}>
            <span
              style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                width: 151,
              }}
            >{
                allSelected.length > 0 ?
                  allSelected
                    .map(item => allItem.find(val => val.value === item))
                    .filter((item): item is Data => !!item)
                    .map(item => item.name)
                    .join(", ") : emptySub
              }</span>
            <img src={drop_down_icon} alt="drop_down_icon" />
          </div>
        </div>) : <div></div>
      
      
    )
  }

  renderSelectedFlow = (item: Data) => (
    <MenuDropdown
      datas={this.state.subFlowData}
      selected={this.state.selectedSubFlowData}
      onApply={this.onApplySubFlow}
      renderValue={this.renderSelectedView(item, this.state.subFlowData, this.state.selectedSubFlowData, 'Flow subcategory')}
    />
  )

  renderSelectedStage = (item: Data) => (
    <MenuDropdown
      datas={this.state.subStageData}
      selected={this.state.selectedSubStage}
      onApply={this.onApplySubStage}
      renderValue={this.renderSelectedView(item, this.state.subStageData, this.state.selectedSubStage, 'Stage+box')}
    />
  )
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div style={styles.analyticsWrapper} >

        <div>
          <AppBar position="static" style={styles.analyticsHeader}>
            <div className="analyticsSearchWrap">
              <h1>Helios</h1>
              <div className="analyticsSearch" >
                <div className="analyticsSearchIcon">
                  <SearchIcon style={{ color: '#94A3B8' }} />
                </div>
                <InputBase
                  placeholder="Search by keyword"
                  // classes={{
                  //   root: classes.inputRoot,
                  //   input: classes.inputInput,
                  // }}
                  onChange={this.handleSearchChange}
                  inputProps={{ 'aria-label': 'search' }}
                  style={{ height: '44px', width: 'calc(100% - 12px)', borderRadius: '2px' }}
                  data-test-id='searchBox'
                />
                
              </div>
              <Button data-test-id="flow-mode-button" onClick={this.handleNavigateWhiteboard} disableElevation variant="contained" style={{ fontFamily: 'Inter', color: '#6200EA', backgroundColor: '#F0E5FF', width: '80', height: '35px' }}>
                      Flow Mode
              </Button>
            </div>

            <Grid container>
              <Grid item xs={12}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    margin: '0px 30px',
                    gap: 5,
                    maxWidth: '100%'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      alignItems: 'center',
                      gap: 6,
                    flexShrink: 1, maxWidth: '65%'

                    }}
                  >
                    
                    <MenuDropdown
                      data-test-id={`add-stage`}
                      datas={this.state.stageData}
                      selected={this.state.selectedStage}
                      onApply={this.onApplyStage}
                      renderValue={() => (
                        <Box
                          style={styles.actionButtonv2}
                        >
                          

                          <span >Stage{this.state.selectedStage.length > 0 ? ` (${this.state.selectedStage.length})` : ''}</span>
                          <img src={drop_down_icon} alt="drop_down_icon" />
                          
                        </Box>
                        )}
                    />
                    
                    
                    
                    <MenuDropdown
                      data-test-id={`add-surface`}
                      datas={this.state.boxData}
                      selected={this.state.selectedSurfaceData}
                      onApply={this.onApplySurface}
                      renderValue={() => (
                        <Box
                          style={styles.actionButtonv2}
                        >
                          <span>Box{this.state.selectedSurfaceData.length > 0 ? ` (${this.state.selectedSurfaceData.length})` : ''}</span>
                          <img src={drop_down_icon} alt="drop_down_icon" />
                        </Box>
                        )}
                    />
                    
                    <MenuDropdown
                      data-test-id={`add-flow`}
                      datas={this.state.flowData}
                      selected={this.state.selectedFlowData}
                      onApply={this.onApplyFlow}
                      renderValue={() => (
                        <Box
                          style={styles.actionButtonv2}
                        >
                          <span>Flow{this.state.selectedFlowData.length > 0 ? ` (${this.state.selectedFlowData.length})` : ''}</span>
                          
                          <img src={drop_down_icon} alt="drop_down_icon" />
                        </Box>
                        )}
                    />
                  </div>
                    
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end',  flexShrink: 1, maxWidth: '35%'}}>
                    <div
                      style={{ ...styles.rightFilter, backgroundColor: this.state.showDatePicker ? "#e0ccff" : "", padding: "8px", border: this.state.showDatePicker ? "1px solid #741ded" : "", borderRadius: "8px", justifyContent: "center" }}
                      onClick={this.handleDatePicker}
                      data-test-id="date-picker"
                    >
                      <img src={calendar_Icon} alt="calendar_Icon" />
                      <span>{this.getFormattedDate() ? this.getFormattedDate() : "Filter Date"}</span>
                    </div>
                    <div
                      data-test-id="some"
                      aria-describedby={this.state.popoverId}
                      style={{ ...styles.rightFilter, width: 139 }}
                      onClick={(event) => this.handleOpenCountriesModal(event)}
                    >
                      <img src={country} alt="country_icon" />
                      <span>Country</span>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
            {this.state.showDatePicker && <div style={{ display: 'flex', position: 'absolute'}}>
              <ClickAwayListener onClickAway={this.handleDatePicker}>
                <Popover
                  data-test-id="countryText"
                  id={this.state.popoverId}
                  open={this.state.calendarPopover}
                  anchorEl={this.state.calendarAnchorEl}
                  onClose={this.handleDatePicker}
                  style={{margin: 4}}
                  PaperProps={{
                    style: {
                      boxShadow: 'none',
                      borderRadius: "8px",
                      border: "1px solid #E2E8F0"
                    },
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}

                >
                  <Calendar
                    onChange={this.handleDateChange}
                    tileClassName={(data) =>
                      this.state.selectedDates?.find((d) => d?.getTime() === data?.date.getTime()) ? 'selected' : ''
                    }
                    selectRange
                  />
                </Popover>
              </ClickAwayListener>
            </div>}
            <Popover
              data-test-id="countryText"
              id={this.state.popoverId}
              open={this.state.popoverOpen}
              anchorEl={this.state.anchorEl}
              onClose={this.handleCountryModalClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}

            >
              <div style={{ width: '250px', height: '390px', top: '20px' }}>
                <div style={{ height: '390px', width: '250px', backgroundColor: '#fff', borderRadius: '20px', boxShadow: '0px 2px 4px rgba(0,0,0,0.1)', }}>

                  <div style={{ maxHeight: '300px', overflow: 'hidden' }}>
                    <div style={{ overflow: 'auto', maxHeight: '100%', padding: '8px' }}>
                      <div className="analyticsSearch" style={{ marginTop: '13px', marginRight: '13px', marginLeft: '13px', width: '210px', borderRadius: '5px' }}>
                        <div className="analyticsSearchIcon">
                          <SearchIcon style={{ color: '#94A3B8' }} />
                        </div>
                        <InputBase
                          placeholder="Search"
                          // classes={{
                          //   root: classes.inputRoot,
                          //   input: classes.inputInput,
                          // }}
                          onChange={this.handleSearchChange}
                          inputProps={{ 'aria-label': 'search' }}
                          style={{ height: '30px', width: '220px', fontFamily: 'Inter' }}

                        />
                      </div>
                      {/* <FormControlLabel
                        control={<Checkbox style={{marginLeft:'40px'}} name="gilad" />}
                        label="Show all"
                        labelPlacement="start"
                      /> */}
                      <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0 20px', marginBottom: '10px', fontFamily: 'Inter' }}>
                        <p>Show all</p>
                        <Checkbox style={{ borderRadius: '50px' }} />
                      </div>
                      <List style={{ padding: 0 }}>

                        <div style={{
                          maxHeight: '200px', // Adjust the height according to your needs
                          overflowY: 'auto',
                          scrollbarWidth: 'none', /* For Firefox */
                          msOverflowStyle: 'none', /* For Internet Explorer and Edge */
                        }}>
                          {this.state.countries.map(country => (
                            <ListItem key={country} style={{ paddingLeft: '20px', fontSize: '10px' }}>
                              <ListItemText primary={<span style={{fontFamily: 'Inter'}} >{country}</span>} />
                              <ListItemSecondaryAction>
                                <Checkbox />
                              </ListItemSecondaryAction>
                            </ListItem>
                          ))}
                        </div>
                      </List>
                    </div>
                  </div>
                  <Divider />
                  <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '8px', marginTop: '10px' }}>
                    <Button disableElevation variant="contained" style={{ fontFamily: 'Inter', color: '#6200EA', width: '80', height: '35px', marginRight: '8px', backgroundColor: 'transparent' }}>
                      Cancel
                    </Button>
                    <Button disableElevation variant="contained" style={{ fontFamily: 'Inter', color: '#6200EA', backgroundColor: '#F0E5FF', width: '80', height: '35px' }}>
                      Apply
                    </Button>
                  </div>
                </div>
              </div>
            </Popover>


            
          </AppBar>
        </div>
        <div style={webStyle.mainWrapper}>
        
        <div style={webStyle.scndWrapper}>

          <div style={webStyle.flexdisplay} >
          
            <svg ref={this.chartRef3} width={this.state.sChartWidth} height={800} style={{
              backgroundColor: "white",
            }}>
              <g />
            </svg>

          </div>
        </div>
      </div >
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  dropdownContainer: {
    position: 'relative',
    display: 'inline-block',
  },
  button: {
    cursor: 'pointer',
    // Add other button styles as needed
  },
  dropdown: {
    // position: 'absolute',
    backgroundColor: '#fff',
    boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
    width: '300px',
    height: '447px',
    zIndex: 1000,
    left: '710px',
    bottom: '19px',
    // Add other dropdown styles as needed
  },
  searchInput: {
    width: 'calc(100% - 20px)', // Adjust the width as needed
    padding: '10px',
    margin: '10px',
    // Add other input styles as needed
  },
  countryItem: {
    padding: '10px',
    borderBottom: '1px solid #ddd',
    // Add other item styles as needed
  },
  actionButton: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 10,
    alignItems: 'center',
    fontFamily: 'Inter',
    fontWeight: 700,
    fontSize: 16,
    padding: 10,
    cursor: 'pointer',
  },
  renderSelectedView: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 10,
    border: '1px solid #CBD5E1',
    background: '#F8FAFC',
    borderRadius: 8,
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: 0,
    textAlign: 'left',
  },
  rightFilter: {
    fontFamily: 'Inter',
    fontWeight: 700,
    fontSize: 14,
    color: '#475569',
    listStyleType: 'none',
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    cursor: 'pointer',
    justifyContent: "center"
  },
  formControlLabel: {
    fontFamily: 'Inter',
  },
  analyticsWrapper: {
    background: 'linear-gradient(135deg, #F4EEFC 0%, #FAEEEC 100%',
    height: '100vh',
    fontFamily: 'Inter',
    fontWeight: 400,
    display: 'flex',
    flexDirection: 'column',
  },
  analyticsHeader: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  actionButtonv2:{
    fontSize: 14,
    display: 'flex',
    backgroundColor: '#F0E5FF',
    border: '1px solid #F0E5FF',
    padding: '8px 10px',
    borderRadius: 4,
    fontWeight: 700,
    gap: 4,
    alignItems: 'center',
    fontFamily: 'Inter',
    justifyContent: 'center'
    
  }
} as const;

// Customizable Area End
