import { window } from 'd3';
import React, { Component } from 'react';

type Props = {
  text: string;
}

type State = {
  isTruncated: boolean;
  showMore: boolean;
}

type SS = {

}

export default class Truncate extends Component<Props, State, SS> {
  textRef = React.createRef<HTMLParagraphElement>()

  constructor(props: Props) {
    super(props);

    this.state = {
      isTruncated: false,
      showMore: false,
    }
  }

  componentDidMount() {
    this.checkIfTruncated()
    addEventListener('resize', this.checkIfTruncated)
  }

  componentWillUnmount() {
    removeEventListener('resize', this.checkIfTruncated)
  }

  checkIfTruncated = () => {
    const element = this.textRef.current
    if (element) {
      const isTruncated = element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth
      if (!this.state.showMore && isTruncated !== this.state.isTruncated) {
        this.setState({ isTruncated })
      }
    }
  }

  toggleSeeMore = () => {
    this.setState(prev => ({ ...prev, showMore: !prev.showMore }))
  }

  render() {
    return (
      <div style={styles.container}>
        <p
          ref={this.textRef}
          style={{
            ...styles.parag,
            ...(this.state.showMore ? {} : styles.paragTrunc)
          }}
        >
          {this.props.text}
        </p>
        {this.state.isTruncated && (this.state.showMore ?
          (<span data-test-id='showLessID' style={styles.showToggle} onClick={this.toggleSeeMore}>Show less</span>) :
          (<span data-test-id='showMoreID' style={styles.showToggle} onClick={this.toggleSeeMore}>Show more</span>))}
      </div>
    )
  }
}

const styles = {
  container: {
    margin: '14px 0px',
    minHeight: '4.75em',
  },
  parag: {
    margin: 0,
    color: '#595959',
    fontSize: '14px',
    fontFamily: "'Rubik', sans-serif",
    fontWeight: '400',
    lineHeight: '24px',
  },
  paragTrunc: {
    WebkitLineClamp: 2,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
  showToggle: {
    color: '#595959',
    fontSize: '14px',
    fontFamily: "'Rubik', sans-serif",
    fontWeight: 'bold',
    lineHeight: '24px',
    cursor: 'pointer',
  },
} as const;
